import Axios from '../../config/axios';

export function getPatientsByLookup(postdata) {
    return Axios.post(`/api/GetPatientsByLookup`, postdata
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function createUser(newUser) {
    return Axios.post('/api/CreateUser', newUser).then(response => response.data);
}

export function sendResetPasswordLink(user) {
    return Axios.post('/api/SendResetPasswordLink', user).then(response => response.data);
}

export function resetPassword(userInfo) {
    return Axios.post('/api/ResetPassword', userInfo).then(response => response.data);
}

export function validateResetToken(token) {
    return Axios.get('/api/ValidateResetToken', {
        params: {
            token
        }
    }).then(response => response.data);
}

export function updatePasswords() {
    return Axios.post('/api/UpdatePasswords').then(response => response.data);
}