import React, { useEffect, useMemo, useState } from 'react';
import { getMostRecentWhatsNewPost, getActiveWhatsNewPosts } from '../../common/services/WhatsNewService';
import UpdatePost from './UpdatePost';

import Moment from 'moment';

function WhatsNew() {

    const [showUpdate, setShowUpdate] = useState({
        show: false,
        id: null
    });

    const [postList, setPostList] = useState([]);

    const isAdmin = JSON.parse(window.sessionStorage.getItem("userInfo"))?.isAdmin;

    useEffect(() => {
        if (!showUpdate.show) {
            getActiveWhatsNewPosts().then(data => {
                setPostList(data);
            });
        }
    }, [showUpdate.show]);

    function shouldShowUpdate(id) {
        setShowUpdate({
            show: !showUpdate.show,
            id
        });
    }

    const filteredList = useMemo(() => {
        if (isAdmin) {
            return postList;
        } else {
            return postList.filter(p => p.active);
        }
    }, [postList]);

    return (
        <div className="row">
            {isAdmin &&
                <div className="col-12">
                    <button className="btn btn-submit float-right" onClick={() => shouldShowUpdate(null)}>{showUpdate.show ? 'Cancel' : "Add What's New Post"}</button>
                </div>
            }

            {showUpdate.show ?
                <UpdatePost id={showUpdate.id} backToPosts={() => setShowUpdate({ ...showUpdate, show: false })} />
                :
                <>
                    {filteredList.map((postData, i) =>
                        <div className="col-12" key={i}>
                            <div className="container mt-3" style={{ borderBottom: '1px black solid' }}>
                                <span>
                                    <h2><u>{Moment(postData.datePublished).utc().format("L")}</u></h2>
                                    {!postData.active && <i className="float-right text-danger">(Inactive)</i>}
                                    {isAdmin && <i className="fas fa-edit hover fa-1x text-icon" onClick={() => shouldShowUpdate(postData.whatsNewID)}></i>}
                                </span>
                                <div className="pt-4" dangerouslySetInnerHTML={{ __html: postData?.htmlContent }}></div>
                            </div>
                        </div>

                    )}
                    
                </>
            }
        </div>    
    );
}

export default WhatsNew;