import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReportDefinitionTable from './ReportDefinitionTable';
import Axios from '../../../config/axios';

import { Multiselect } from 'multiselect-react-dropdown';
import 'react-widgets/dist/css/react-widgets.css';
import OrderComponents from './OrderComponents';
import { getReportDefinitionPieces } from '../../../common/services/ReportService';

class ReportDefinitions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            definitions: [],
            definition: null,
            loading: true,
            loadDefinition: true,
            showModal: false,
            formFields: {
                definitionMasterID: "00000000-0000-0000-0000-000000000000",
                templateID: "00000000-0000-0000-0000-000000000000",
                definitionName: "",
                inactiveYN: 0
            },
            reportTemplatesList: [],
            showToast: false,
            toastContent: {
                title: '',
                content: ''
            },
            reportComponentsList: [],
            selectedReportComponents: []
        }

        this.handleClick = this.handleClick.bind(this);
        this.GetAllTemplates = this.GetAllTemplates.bind(this);
        this.GetAllComponents = this.GetAllComponents.bind(this);
        this.GetAllDefinitions = this.GetAllDefinitions.bind(this);
        this.addNew = this.addNew.bind(this);
        this.toggleToast = this.toggleToast.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        this.GetAllTemplates();
        this.GetAllComponents();
        this.GetAllDefinitions();

        getReportDefinitionPieces();
    }

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'backToResults':

                this.setState({
                    patient: null,
                    loading: true,
                    loadPatient: true
                }, () => this.GetAllPatients());
                break;
            case 'closeModal':
                this.setState({
                    showModal: !this.state.showModal
                });
                break;
            default:
                break;
        }
    }

    editDefinition(definition) {

        let postdata = { definitionMasterID: definition.definitionMasterID }

        Axios.post(`/api/GetReportDefinitionDetailByMasterID`, postdata
        ).then(response => {
            this.setState({
                formFields: definition,
                selectedReportComponents: response.data,
                showModal: true
            });
        }).catch(error => {
            console.log(error);
        });

    }

    addNew(event) {
        event.preventDefault();

        this.setState({
            formFields: {
                definitionMasterID: "00000000-0000-0000-0000-000000000000",
                templateID: "00000000-0000-0000-0000-000000000000",
                definitionName: "",
                inactiveYN: 0
            },
            selectedReportComponents: [],
            showModal: true
        });
    }

    toggleToast(toastContent) {
        if (toastContent) {
            this.setState({
                showToast: !this.state.showToast,
                toastContent
            });
        } else {
            this.setState({
                showToast: !this.state.showToast
            });
        }
    }

    GetAllDefinitions() {
        Axios.get(`/api/GetAllDefinitions`
        ).then(response => {
            this.setState({
                definitions: response.data,
                loading: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    GetAllTemplates() {
        Axios.get(`/api/GetAllTemplates`
        ).then(response => {
            let reportTemplatesList = response.data;
            this.setState({
                reportTemplatesList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    GetAllComponents() {
        Axios.get(`/api/GetAllComponents`
        ).then(response => {
            let reportComponentsList = response.data;
            this.setState({
                reportComponentsList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = "";
        if (target.type === 'checkbox') {
            value = target.checked === true ? "1" : "0";
        }
        else {
            value = target.value;
        }
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit(event) {
        event.preventDefault();

        let ffCopy = { ...this.state.formFields };

        let postdata = {
            reportDefinitionMasterModel: ffCopy,
            reportDefinitionComponentsList: this.state.selectedReportComponents
        }

        let toastContent = {
            title: '',
            content: ''
        };

        if (ffCopy.definitionMasterID && ffCopy.definitionMasterID !== "00000000-0000-0000-0000-000000000000") {
            //if updating current entry
            Axios.post(`/api/UpdateReportDefinition`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Definition has been updated."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllDefinitions();
            }).catch(error => {
                this.notifyFail('Fail');
            });
        } else {
            //if new entry
            Axios.post(`/api/InsertReportDefinition`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Definition has been added."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllDefinitions();
            }).catch(error => {
                console.log(error);
            });
        }
    }

    onSelectReportComponent = (selectedReportComponents) => {
        this.setState({
            selectedReportComponents
        });
    }

    onRemoveReportComponent = (selectedReportComponents) => {
        this.setState({
            selectedReportComponents
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.loadDefinition &&
                    <div className="row">
                        <div className="col-lg-12" >
                            <div className="panel-hdr">
                                <h2>
                                    Report Definitions
                                    </h2>

                                <button type="button" onClick={this.addNew} className="btn btn-outline-primary margin-0-10">
                                    New
                                    </button>
                            </div>
                            <div className="frame-wrap">
                                {!this.state.loading && !this.state.showModal &&
                                    <ReportDefinitionTable data={this.state.definitions} editDefinition={(definition) => this.editDefinition(definition)} />
                                }
                            </div>
                        </div >
                    </div >
                }

                {this.state.showModal &&
                    <div style={{ backgroundColor: 'gainsboro', padding: '10px' }}>
                        <div className="panel-content">
                            <form>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Definition Name</label>
                                        <input type="text" name="definitionName" value={this.state.formFields.definitionName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Definition Template</label>
                                        <select className="form-control-custom" value={this.state.formFields.templateID} name="templateID" onChange={this.handleInputChange}>
                                            <option></option>
                                            {this.state.reportTemplatesList.map((rt, idx) => <option key={idx} value={rt.templateID}>{rt.templateName}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label className="form-label">Definition Components</label>
                                        <Multiselect
                                            options={this.state.reportComponentsList}
                                            selectedValues={this.state.selectedReportComponents}
                                            onSelect={this.onSelectReportComponent}
                                            onRemove={this.onRemoveReportComponent}
                                            displayValue="componentName"
                                        />

                                    </div>
                                </div>
                            </form>
                        </div>
                        <button type="button" onClick={this.handleSubmit} className="btn btn-primary">
                            Save
                        </button>
                        &nbsp;<a id="closeModal" onClick={this.handleClick}>Cancel</a>

                    </div>
                }

                <OrderComponents />

            </div>
        );
    }
}

export default withRouter(ReportDefinitions);