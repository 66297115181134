import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Globals from '../../config/globals';
import { getProvidersByFacility, updateProvider, getAllProvidersWithFacilities } from '../../common/services/MaintenanceService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Moment from 'moment';
import $ from 'jquery';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { Empty, Select, message } from 'antd';

import { Modal } from 'react-bootstrap';

import { toast } from '@rickylandino/react-messages';
import { downloadIDNameList } from '../../common/services/ApiService';

function Providers() {

    const { register, handleSubmit, setValue, getValues, watch, setError, clearErrors,
        formState: { errors }, control } = useForm();

    const { SearchBar } = Search;

    const { Option } = Select;

    const [modalInfo, setModalInfo] = useState({
        showModal: false,
        providerItem: {
            providerModel: {
                fName: '',
                mName: '',
                lName: '',
                title: '',
                inactiveYN: false,
                npi: ''
            }
        },
        selectedFacilities: []
    });

    const [duplicateModalInfo, setDuplicateModalInfo] = useState({
        showModal: false,
        providerItem: {
            providerModel: {
                fName: '',
                mName: '',
                lName: '',
                title: '',
                inactiveYN: false,
                npi: ''
            }
        },
        selectedFacilities: [],
        filteredFacilityList: [],
        filteredSelectedFacilities: [],
        existingFacilities: []
    });

    const [state, setState] = useState({
        providerList: [],
        loaded: false,
        facilityList: Globals.associatedFacilities,
        selectedFacility: {},
        selectedFacilityID: '',
        filteredProviderList: []
    });



    useEffect(() => {
        getAllProvidersWithFacilities(JSON.parse(window.sessionStorage.getItem("userInfo"))?.userId).then(data => {
            let defaultFacility = JSON.parse(window.sessionStorage.getItem("defaultFacility"));

            let selectedFacility = {};

            if (defaultFacility?.facilityID) {
                selectedFacility = Globals.associatedFacilities.find(fac => fac.facilityModel.facilityID === defaultFacility.facilityID).facilityModel;
            }

            setState({
                ...state,
                providerList: data,
                selectedFacility: selectedFacility,
                selectedFacilityID: selectedFacility?.facilityID || ''
            });
        });
    }, []);

    useEffect(() => {
        let filteredProviderList = state.providerList.filter(pL => {
            if (pL.facilityListNames.includes(state.selectedFacility?.facilityName)) {
                return pL;
            }
        });

        setState({
            ...state,
            filteredProviderList,
            loaded: true
        });

    }, [state.selectedFacilityID, state.providerList]);

    function closeModal() {
        setModalInfo({
            showModal: false,
            providerItem: {
                providerModel: {
                    fName: '',
                    mName: '',
                    lName: '',
                    title: '',
                    inactiveYN: false,
                    npi: ''
                }
            }
        });
    }

    function closeDuplicateModal() {
        setDuplicateModalInfo({
            showModal: false,
            providerItem: {
                providerModel: {
                    fName: '',
                    mName: '',
                    lName: '',
                    title: '',
                    inactiveYN: false,
                    npi: ''
                }
            },
            selectedFacilities: [],
            filteredFacilityList: [],
            filteredSelectedFacilities: [],
            existingFacilities: []
        });
    }

    function handleModalFacilityChange(selectedFacilities) {
        setModalInfo({
            ...modalInfo,
            selectedFacilities
        });
    }

    function handleSecondModalFacilityChange(selectedFacilities) {
        setDuplicateModalInfo({
            ...duplicateModalInfo,
            filteredSelectedFacilities: selectedFacilities
        });
    }

    function interceptUpdate() {

        let postdata = null

        if (duplicateModalInfo?.providerItem?.providerModel) {

            let guidList = duplicateModalInfo.filteredSelectedFacilities;

            for (var i = 0; i < duplicateModalInfo.existingFacilities.length; i++) {
                var ef = duplicateModalInfo.existingFacilities[i];
                guidList.push(ef.facilityID);
            }

            postdata = {
                provider: duplicateModalInfo.providerItem.providerModel,
                guidList,
                userID: JSON.parse(window.sessionStorage.getItem("userInfo"))?.userId
            }
        }

        handleUpdate(postdata);
    }

    function interceptAdd() {
        let postdata = {
            overrideLogic: true,
            provider: duplicateModalInfo.newProviderItem,
            guidList: duplicateModalInfo.filteredSelectedFacilities,
            userID: JSON.parse(window.sessionStorage.getItem("userInfo"))?.userId
        }

        handleUpdate(postdata);
    }

    function handleUpdate(postdata) {

        var fromDuplicateModal = false;

        if (!postdata) {
            var providerItem = modalInfo.providerItem.providerModel;

            providerItem.inactiveYN = getValues().inactiveYN ? 1 : 0;
            providerItem.fName = getValues().fName;
            providerItem.mName = getValues().mName;
            providerItem.lName = getValues().lName;
            providerItem.title = getValues().title;
            providerItem.npi = getValues().npi;

            postdata = {
                provider: providerItem,
                guidList: modalInfo.selectedFacilities,
                userID: JSON.parse(window.sessionStorage.getItem("userInfo"))?.userId
            }
        } else {
            fromDuplicateModal = true;
        }

        if (postdata.guidList?.length > 0) {
            updateProvider(postdata).then(data => {
                if (data?.providerModel?.providerID) {
                    closeModal();

                    let filteredFacilityList = [];

                    state.facilityList.forEach(f => {
                        let toReturn = true;

                        data.facilityList.forEach(ef => {
                            if (ef.facilityID === f.facilityModel.facilityID) {
                                toReturn = false;
                            }
                        });

                        if (toReturn) {
                            filteredFacilityList.push(f);
                        }
                    });

                    let filteredSelectedFacilities = [];


                    filteredFacilityList.forEach(ffl => {
                        let item = modalInfo.selectedFacilities.find(sf => sf === ffl.facilityModel.facilityID);

                        if (item) {
                            filteredSelectedFacilities.push(item.toString());
                        }
                    });

                    setDuplicateModalInfo({
                        showModal: true,
                        newProviderItem: providerItem,
                        providerItem: {
                            providerModel: data.providerModel
                        },
                        existingFacilities: data.facilityList,
                        filteredFacilityList,
                        filteredSelectedFacilities
                    });
                } else {
                    toast.success("Provider Updated");

                    setState({
                        ...state,
                        providerList: [...data],
                        loaded: true
                    });

                    if (fromDuplicateModal) {
                        closeDuplicateModal();
                    } else {
                        closeModal();
                    }
                }
            });
        } else {
            toast.warning("Please fill in all required information");
        }
    }

    function openBlank() {
        setValue("inactiveYN", false);
        setValue("fName", '');
        setValue("mName", '');
        setValue("lName", '');
        setValue("title", '');
        setValue("npi", '');

        setModalInfo({
            show: true,
            providerItem: {
                providerModel: {
                    fName: '',
                    mName: '',
                    lName: '',
                    title: '',
                    inactiveYN: false,
                    npi: ''
                },
                selectedFacilities: []
            }
        });
    }

    function handleFacilityChange(selectedFacilityID) {

        let selectedFacility = Globals.associatedFacilities.find(fac => fac.facilityModel.facilityID === selectedFacilityID).facilityModel;

        setState({
            ...state,
            selectedFacility,
            selectedFacilityID
        });
    }

    function handleProviderExport() {
        const key = "loading";
        message.loading({ content: 'Exporting Providers...', key, duration: 0 });
        let fileName = "provider-results_" + Moment().format('YYYYMMDDHm') + '.xlsx';

        const provList = state.filteredProviderList.map(fp => ({
            id: fp.providerModel.providerID,
            name: fp.providerModel.fName + ' ' + (fp.providerModel.mName && (fp.providerModel.mName + ' ')) + fp.providerModel.lName
        }));

        downloadIDNameList(provList, fileName, "Providers").then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);

        }).catch(error => {
            console.log("Error");
        });
    }

    function nameFormatter(cell, row) {
        var name = '';

        name += row.providerModel.fName ? row.providerModel.fName + ' ' : '';
        name += row.providerModel.mName ? row.providerModel.mName + ' ' : '';
        name += row.providerModel.lName ? row.providerModel.lName : '';

        return (
            <span>{name}</span>
        );
    }

    const columns = [{
        dataField: 'providerModel.fName',
        text: 'Name',
        formatter: nameFormatter,
        sort: true
    }, {
            dataField: 'providerModel.lName',
            text: 'Last Name',
            sort: true,
            hidden: true
        }, {
        dataField: 'providerModel.title',
        text: 'Title',
        sort: true
    }, {
        dataField: 'facilityListNames',
        text: 'Facilities',
        sort: true
    }, {
        dataField: 'providerModel.npi',
        text: 'NPI',
        sort: true
    }];

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role="presentation"
            className="form-control-custom hover"
            tabIndex="-1"
            data-page={page}
            onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            }}
            style={{ color: 'pink' }}
        >
            <span
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'rgba(243, 0, 121, 1)' }}
            >
                {text}
            </span>
        </li>
    );

    const pagination = {
        sizePerPage: 5,
        sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
        sizePerPageOptionRenderer
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            setValue("fName", row.providerModel.fName);
            setValue("mName", row.providerModel.mName);
            setValue("lName", row.providerModel.lName);
            setValue("title", row.providerModel.title);
            setValue("inactiveYN", row.providerModel?.inactiveYN);
            setValue("npi", row.providerModel?.npi);

            let selectedFacilities = row.facilityList.map(f => (f.facilityID));

            setModalInfo({
                providerItem: row,
                show: true,
                selectedFacilities
            });

        }
    };

    const rowClasses = (row, rowIndex) => {
        if (row.providerModel.inactiveYN) {
            return "user-inactive";
        }
    };

    return (
        <div>
            {/*<div className="secondary-header">*/}
            {/*    {Globals.associatedFacilities.length === 1 ?*/}
            {/*        <div className="form-group col-lg-3">*/}
            {/*            <label className="form-label">Facility</label>*/}
            {/*            <div>{state?.selectedFacility?.facilityName}</div>*/}
            {/*        </div>*/}
            {/*        :*/}
            {/*        <div className="form-group col-lg-3">*/}
            {/*            <Select*/}
            {/*                allowClear*/}
            {/*                placeholder="Please select"*/}
            {/*                onChange={handleFacilityChange}*/}
            {/*                className="form-control-custom w-100"*/}
            {/*                bordered={false}*/}
            {/*                value={state?.selectedFacilityID || ''}*/}
            {/*                showSearch*/}
            {/*                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}*/}
            {/*            >*/}
            {/*                {Globals.associatedFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}*/}
            {/*            </Select>*/}
                        
            {/*        </div>*/}
            {/*    }*/}


            {/*</div>*/}

            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            Providers
                        </h2>

                        <button type="button" className="btn btn-submit margin-0-10" onClick={openBlank}>
                            New Provider
                        </button>
                        <button type="button" className="pinkTextA btn btn-outline-default margin-0-10" onClick={handleProviderExport}>
                            Export List
                        </button>

                    </div>
                </div>
            </div>

            <div className="mt-5">
                {!state.loaded ?
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                No Providers Found
                            </span>
                        }
                    >
                    </Empty>
                    :
                    <div className="m-5">
                        <ToolkitProvider
                            keyField='providerModel.providerID'
                            data={state.providerList}
                            columns={columns}
                            columnToggle
                            search
                        >
                            {
                                props => (
                                    <div>
                                        <SearchBar {...props.searchProps} />
                                        <BootstrapTable
                                            pagination={paginationFactory(pagination)}
                                            {...props.baseProps}
                                            rowEvents={rowEvents}
                                            rowClasses={rowClasses}
                                            hover condensed />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                }
            </div>

            <Modal size='lg' show={modalInfo.show} backdrop='static' onHide={closeModal}>
                <Modal.Header>
                    <h1>Update Provider</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                        <div className="row">
                            <div className="form-group col-lg-5 col-12">
                                <label className="form-label">First Name</label>
                                <input className="form-control-custom" {...register("fName")}/>
                            </div>
                            <div className="form-group col-lg-2 col-12">
                                <label className="form-label">Middle Initial</label>
                                <input className="form-control-custom" {...register("mName")}/>
                            </div>
                            <div className="form-group col-lg-5 col-12">
                                <label className="form-label">Last Name</label>
                                <input className="form-control-custom" {...register("lName")}/>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="form-label">Title</label>
                                <input className="form-control-custom" {...register("title")} />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="form-label">NPI</label>
                                <input className="form-control-custom" {...register("npi")} />
                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Facilities <span className="color-pink">*</span></div>
                                <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    allowClear
                                    placeholder="Please select"
                                    onChange={handleModalFacilityChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    value={modalInfo.selectedFacilities}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {state.facilityList.map((f, idx) => <Option key={f.facilityModel.facilityID} value={f.facilityModel.facilityID}>{f.facilityModel.facilityName}</Option>)}
                                </Select>
                            </div>

                            <div className="form-group col-6">
                                <label className="form-label">Set Inactive</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" {...register("inactiveYN")} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={() => handleUpdate(null)}>Save</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={duplicateModalInfo.showModal} backdrop='static' onHide={closeDuplicateModal}>
                <Modal.Header>
                    <h1>Existing Provider Found</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                        <div className="row">
                            <div className="form-group col-12">
                            <h3>A provider with the same name was found. See details below:</h3>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Name</label>
                                <p>{duplicateModalInfo.providerItem.providerModel.fName} {duplicateModalInfo.providerItem.providerModel.mName} {duplicateModalInfo.providerItem.providerModel.lName}</p>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Title</label>
                                <p>{duplicateModalInfo.providerItem.providerModel.title}</p>
                            </div>

                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">NPI</label>
                                <p>{duplicateModalInfo.providerItem.providerModel.npi}</p>
                            </div>

                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Associated Facilities</label>
                                {duplicateModalInfo?.existingFacilities?.map((f, idx) => <p key={idx}>{f.facilityName}</p>)}
                            </div>

                            <hr />

                            <h3>Would you like to associate the selected facilities to this provider?</h3>

                            <div className="form-group col col-12">
                                <div className="form-label"></div>
                                <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    allowClear
                                    placeholder="Please select"
                                    onChange={handleSecondModalFacilityChange}
                                    className="form-control-custom w-60"
                                    bordered={false}
                                    value={duplicateModalInfo?.filteredSelectedFacilities}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {duplicateModalInfo?.filteredFacilityList.map((f, idx) => <Option key={f.facilityModel.facilityID} value={f.facilityModel.facilityID}>{f.facilityModel.facilityName}</Option>)}
                                </Select>

                                <button className="btn btn-submit float-right" onClick={interceptUpdate}>Update Existing Provider</button>
                            </div>

                            <div className="d-block w-100">
                                <div className="text-hr">OR</div>
                            </div>

                            <h3>Proceed with adding this new provider?</h3>
                            <div className="text-right w-100">
                                <button className="btn btn-submit" onClick={interceptAdd}>Add As New Provider</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={closeDuplicateModal}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default withRouter(Providers);