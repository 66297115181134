import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import Card from 'react-bootstrap/Card';
import '../../../css/card.css';


import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';


// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

import { userAssociatedFacilities } from '../../../common/services/ApiService';
import { definitionsByType } from '../../../common/services/ReportService';
import { getAdminPLLPrintTypeInformation, pllPrintTypes, updatePLLPrintTypes } from '../../../common/services/RecallService';

import { toast } from '@rickylandino/react-messages';

import { Select } from 'antd';

class ManageBiradDefinitions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            definitionIcons: [],
            definitionsByType: {},
            pllDefinitions: [],
            pllPrintTypes: [],
            loaded: false,
            facilities: [],
            formFields: {
                facilityID: ''
            },
            showAddModal: false,
            newIconDefinition: {
                definitionName: 'Select a definition',
                facilityID: '',
                printIconName: ''
            }
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities,
            definitionsByType
        ).pipe(takeWhile(() => this.alive)).subscribe(response => {
            if (response[1]) {
                let facilities = response[0];
                if (facilities.length > 0) {
                    let defaultFacility = facilities.find(f => f.defaultFacility === 1)?.facilityModel?.facilityID || facilities[0].facilityModel.facilityID;

                    getAdminPLLPrintTypeInformation(defaultFacility);
                    let definitionsByType = { ...this.state.definitionsByType };
                    let pllDefinitions = [...this.state.pllDefinitions];
                    Object.keys(response[1]).map(key => {
                        if (key.toLowerCase().includes("plain language letter")) {
                            definitionsByType[key] = response[1][key];
                            pllDefinitions = response[1][key];
                        }
                    });

                    this.setState({
                        facilities,
                        formFields: { ...this.state.formFields, facilityID: defaultFacility },
                        definitionsByType,
                        allDefinitionsByType: response[1],
                        pllDefinitions
                    });
                }
            }
        });

        pllPrintTypes.pipe(takeWhile(() => this.alive)).subscribe(response => {
            if (response.length > 0) {

                this.setState({
                    loaded: false
                });

                let pllPrintTypes = [...this.state.pllPrintTypes];
                pllPrintTypes = [...response];

                this.setState({
                    pllPrintTypes,
                    loaded: true
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            getAdminPLLPrintTypeInformation(this.state.formFields.facilityID);
        });
    }

    handleDefinitionSelection = (eventKey, event, rowIndex) => {
        if (eventKey) {
            

            let splitStr = eventKey.split(", ");
            let guid = splitStr[0];
            let pllPrintTypesID = splitStr[1];

            let definitionSelected = this.state.pllDefinitions.find(item => item.definitionMasterID === guid);

            let pllPrintTypes = [...this.state.pllPrintTypes];

            let itemToChange = pllPrintTypes.findIndex(rpt => rpt.pllPrintTypesID === pllPrintTypesID);

            let item = {
                ...pllPrintTypes[rowIndex],
                reportDefinitionMasterID: definitionSelected.definitionMasterID,
                isDirty: true
            }

            pllPrintTypes[rowIndex] = item;

            this.setState({
                pllPrintTypes,
            });
        }

    }

    handleSubmit = () => {
        let listToUpdate = this.state.pllPrintTypes.filter(i => i.isDirty);
        let postdata = {
            pllPrintTypes: listToUpdate,
            facilityID: this.state.formFields.facilityID
        };

        updatePLLPrintTypes(postdata).then(data => {
            if (data) {
                toast.success("PPL Print Type has been updated");
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });
    }

    handleFacilityChange = (selectedFacilityID) => {
        this.setState({ formFields: { ...this.state.formFields, facilityID: selectedFacilityID } }, () => {
            getAdminPLLPrintTypeInformation(this.state.formFields.facilityID);
        });
    }

    render() {
        let th = this;

        function dropdownFormatter(cell, row, rowIndex, formatExtraData) {
            let pllDefinitions = formatExtraData.pllDefinitions;
            var name = '';

            for (var i = 0; i < pllDefinitions.length; i++) {
                name = pllDefinitions.find(pll => pll.definitionMasterID === cell)?.definitionName || '';
                if (name) break;
            }

            let filteredList = pllDefinitions.filter(item => item.facilityID && item.facilityID === th.state.formFields.facilityID);

            return (
                <div>
                    <Dropdown
                        onSelect={(eventKey, event) => {
                            th.handleDefinitionSelection(eventKey, event, rowIndex);
                        }}
                        className="mw-50 custom-nested-dropdown"
                        dropup={rowIndex > 3}
                    >
                        <Dropdown.Toggle title={name} className="w-100 mh-32" />
                        <Dropdown.Menu style={{maxHeight: '400px', overflow: 'auto'}}>
                            {filteredList.length > 0 && filteredList.map((item, idx1) => {
                                return idx1 === 0 &&
                                    <MenuItem header className="color-pink" key={idx1}>Facility Definitions</MenuItem>
                            })}

                            {filteredList.length > 0 && filteredList.map((item, idx) =>
                                <MenuItem key={idx} eventKey={item.definitionMasterID + ", " + row.pllPrintTypesID}>{item.definitionName}</MenuItem>
                            )}

                            {pllDefinitions.filter(item => !item.facilityID).map((item, idx2) => {
                                return idx2 === 0 &&
                                    <MenuItem header className="color-pink" key={12}>Global Definitions</MenuItem>
                            })}

                            {pllDefinitions.filter(item => !item.facilityID).map((item, idx) =>
                                <MenuItem key={idx} eventKey={item.definitionMasterID + ", " + row.pllPrintTypesID}>{item.definitionName}</MenuItem>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    {row.pllPrintType?.facilityID !== "00000000-0000-0000-0000-000000000000" && <span className="ml-3 fs-24 color-pink">*</span>}
                </div>
            );
        }

        function checkboxFormatter(cell, row, rowIndex) {
            return (
                <div className="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" className="custom-control-input" id="notApplicable" name="showUpcoming" defaultChecked={cell || false} />
                    <label className="custom-control-label"></label>
                </div>
            );
        }

        const columns = [{
            dataField: 'birads',
            text: 'BIRADS'
        }, {
            dataField: 'reportDefinitionMasterID',
            text: 'Selected Definition',
            formatter: dropdownFormatter,
                formatExtraData: {
                    pllDefinitions: this.state.pllDefinitions
            },
        }, {
            dataField: 'notApplicable',
            text: 'Not Applicable',
            formatter: checkboxFormatter,
            headerStyle: () => {
                return { width: '200px' };
            }
        }];
        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.id === 'notApplicable') {
                    let pllPrintTypes = [...this.state.pllPrintTypes];

                    pllPrintTypes[rowIndex].notApplicable = e.target.checked;
                    pllPrintTypes[rowIndex].isDirty = true;

                    this.setState({
                        pllPrintTypes
                    });

                }
            }
        };

        const { Option } = Select;

        return (
            <Fragment>
                {this.state.loaded &&
                    <Fragment>
                        <div className="row justify-content-between">
                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Selected Facility</label>
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={this.handleFacilityChange}
                                className="form-control-custom w-100"
                                bordered={false}
                                value={this.state.formFields.facilityID || ''}
                                showSearch
                                filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                            >
                                {this.state.facilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                            </Select>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <fieldset><span className="ml-3 fs-24 color-pink">*</span> = Facility Specific Plain Language Letter</fieldset>
                            </div>
                        </div>
                        <Card className="pinkCard">
                            <Card.Header><span>Plain Language Letter</span>
                            </Card.Header>
                            <Card.Body>
                                <div className="fullTable">

                                    <BootstrapTable keyField='birads'
                                        data={this.state.pllPrintTypes}
                                        columns={columns}
                                        selectRow={selectRow}
                                        rowEvents={rowEvents}
                                        condensed />

                                </div>
                            </Card.Body>

                        </Card>
                        <button className="btn btn-submit" onClick={this.handleSubmit}>Save</button>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default withRouter(ManageBiradDefinitions);