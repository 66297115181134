import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

import NumberFormat from 'react-number-format';
import ReactLoading from 'react-loading';
import Card from 'react-bootstrap/Card';
import '../../css/card.css';

import Axios from '../../config/axios';

import { toast } from '@rickylandino/react-messages';

class FamilyHistoryTab extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.medicalHistoryLoaded === true) {

            let patientMedicalHistory = prevState.patientMedicalHistory;
            
            for (var i = 0; i < nextProps.patientMedicalHistory.length; i++) {
                if (patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()] && nextProps.patientMedicalHistory[i].patientMedicalHistoryModel) {
                    if (nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString() === 'OtherTypesofCancer') {
                        patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()] = nextProps.patientMedicalHistory[i].patientMedicalHistoryModel;
                        if (patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].value === 'Yes') {
                            var split = patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].typeofCancer.split(", ");
                            for (var j = 0; j < split.length; j++) {
                                switch (split[j]) {
                                    case 'Uterine':
                                        patientMedicalHistory.OtherTypesofCancer.uterine = 'Yes';
                                        break;
                                    case 'Ovarian':
                                        patientMedicalHistory.OtherTypesofCancer.ovarian = 'Yes';
                                        break;
                                    default:
                                        if(split[j])
                                            patientMedicalHistory.OtherTypesofCancer.other = 'Yes';
                                        break;
                            }
                            }
                        }
                    //} else if (nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString() === 'GivenBirth') {
                    //    if (nextProps.patientMedicalHistory[i].patientMedicalHistoryModel.value) {
                    //        patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].yn = 'Yes';
                    //        patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].value = nextProps.patientMedicalHistory[i].patientMedicalHistoryModel.value;
                    //    }
                    //} else if (nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString() === 'Menopause') {
                    //    if (nextProps.patientMedicalHistory[i].patientMedicalHistoryModel.value) {
                    //        patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].yn = 'Yes';
                    //        patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].value = nextProps.patientMedicalHistory[i].patientMedicalHistoryModel.value;
                    //    }
                    } else if (nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString() === 'Height') {
                        
                        if (nextProps.patientMedicalHistory[i].patientMedicalHistoryModel.value) {
                            
                            const splitval = nextProps.patientMedicalHistory[i].patientMedicalHistoryModel.value.split("ft");
                            patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].ft = splitval[0];
                            patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].in = splitval[1];
                        }
                    } else {
                        patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()] = nextProps.patientMedicalHistory[i].patientMedicalHistoryModel;
                        patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].autoFlagYN = nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.autoFlagYN;
                    }
                    
                } else if (patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()]) {
                    patientMedicalHistory[nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.historyItemDescription.toString()].autoFlagYN = nextProps.patientMedicalHistory[i].medicalHistoryItemsModel.autoFlagYN;
                }
            }
            return { patientMedicalHistory }
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            previousMammogram: '',
            lumpsThickness: '',
            focalPain: '',
            bNippleDischarge: 'no',
            cNippleDischarge: 'no',
            molesScarsBruises: 'no',
            nippleRetraction: 'no',
            pregnant: 'no',
            hormones: 'no',
            cancer: 'no',
            breastCancer: '',
            breastSurgery: '',
            biopsySurgery: 'no',
            problemsWithBreasts: 'no',
            menstrualPeriodDate: null,
            lastMammogramDate: null,
            formFields: {

            },
            patientMedicalHistory: {
                FamilyHistoryOfBreastCancer: {
                    value: ''
                },
                MotherHadBreastCancer: {
                    value: '',
                    ageAtDiagnosis: null,
                    notes: ''
                },
                SisterHadBreastCancer: {
                    value: '',
                    ageAtDiagnosis: null,
                    notes: ''
                },
                DaughterHadBreastCancer: {
                    value: '',
                    ageAtDiagnosis: null,
                    notes: ''
                },
                OtherHadBreastCancer: {
                    value: '',
                    ageAtDiagnosis: null,
                    notes: ''
                },
                HaveYouHadBreastCancer: {
                    value: '',
                    laterality: 'Left',
                    ageAtDiagnosis: null,
                    typeofCancer: null,
                    flagYN: 0
                },
                Chemotherapy: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                RadiationTherapy: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                HaveYouHadBreastSurgery: {
                    value: null,
                },
                CystAspiration: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                SurgicalBiopsy: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                NeedleBiopsy: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                Mastectomy: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                Lumpectomy: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                Implants: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                Reconstruction: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                Reduction: {
                    value: null,
                    laterality: 'Left',
                    date: null
                },
                OtherBreastSurgery: {
                    value: null,
                    laterality: 'Left',
                    date: null,
                    notes: null
                },
                PreviousMammogram: {
                    value: null,
                    facility: '',
                    date: null
                },
                OtherBreastProblems: {
                    value: null,
                    laterality: 'Left',
                    newYN: null,
                    notes: ''
                },
                OtherBreastMarkings: {
                    value: null,
                    notes: ''
                },
                Hormones: {
                    value: null,
                    typeofHRT: '',
                    lengthofHRTUse: '',
                    timeSinceHRTUse: '',
                    intendedLengthofHRTUse: ''
                },
                OralContraceptives: {
                    value: null
                },
                DateofLastMenstrualPeriod: {
                    date: null
                },
                PatientPregnant: {
                    value: null
                },
                OtherTypesofCancer: {
                    typeofCancer: null,
                    notes: null,
                    none: null,
                    ovarian: null,
                    uterine: null,
                    other: null
                },
                LumpsThickness: {
                    value: null,
                    notes: null,
                    laterality: 'Left',
                },
                FocalPain: {
                    value: null,
                    notes: null
                },
                NippleDischarge: {
                    value: null,
                    notes: null,
                    clearBloody: null,
                    laterality: 'Left',
                },
                BloodyNippleDischarge: {
                    value: null,
                    notes: null
                },
                NippleRetraction: {
                    value: null,
                    notes: null,
                    laterality: 'Left',
                    newYN: null
                },
                SurgicalBiospy: {
                    value: null,
                    ageAtDiagnosis: null
                },
                BreastSymptoms: {
                    notes: null
                },
                Moles: {
                    value: null,
                    laterality: 'Left',
                    newYN: null,
                    notes: null
                },
                Scars: {
                    value: null,
                    laterality: 'Left',
                    newYN: null,
                    notes: null
                },
                Bruises: {
                    value: null,
                    laterality: 'Left',
                    newYN: null,
                    notes: null
                },
                BreastPain: {
                    value: null,
                    laterality: 'Left',
                    focalPainYN: null,
                    notes: null
                },
                PatientHasBreastProblems: {
                    value: null
                },
                PatientHasBreastMarkings: {
                    value: null
                },
                Height: {
                    ft: null,
                    in: null
                },
                Weight: {
                    value: null
                },
                AgeatFirstMenstrualPeriod: {
                    value: null
                },
                GivenBirth: {
                    value: null,
                    ageAtDiagnosis: null,
                    yn: 'No'
                },
                Menopause: {
                    value: null,
                    ageAtDiagnosis: null,
                    yn: 'No'
                },
                GeneticMutation: {
                    value: null
                },
                AJAncestry: {
                    value: null
                }
            },
            updateHistory: false

        }

        this.onCalendarChange = this.onCalendarChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.isDirty = false;
    }

    componentDidUpdate(prevProps) {

        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
        if (this.props.updateHistory === true && (this.props.clickedAdd)) {

            if (this.isDirty) {
                let kvp = this.state.patientMedicalHistory;

                kvp.OtherTypesofCancer.value = this.state.patientMedicalHistory.OtherTypesofCancer.none === 'Yes' ? "No" : "Yes";
                if (kvp.OtherTypesofCancer.value !== 'No') {
                    let typeofCancer = '';
                    if (kvp.OtherTypesofCancer.ovarian === 'Yes') {
                        typeofCancer += 'Ovarian, ';
                    }
                    if (kvp.OtherTypesofCancer.uterine === 'Yes') {
                        typeofCancer += 'Uterine, ';
                    }
                    if (kvp.OtherTypesofCancer.other) {
                        typeofCancer += kvp.OtherTypesofCancer.notes ? kvp.OtherTypesofCancer.notes : '';
                    }
                    kvp.OtherTypesofCancer.typeofCancer = typeofCancer;
                } else {
                    kvp.OtherTypesofCancer.typeofCancer = null;
                }


                let postdata = {
                    medicalHistoryKVP: kvp,
                    uniqueID: this.props.patient.patientID,
                    userID: userInfo.userId
                }

                Axios.post(`/api/UpdatePatientMedicalHistory`, postdata
                ).then(response => {
                    toast.success("Patient history has been updated");

                    this.props.updateClickedAdd();
                    this.props.familyHistoryDirty(true);
                    this.isDirty = false;
                }).catch(error => {
                    console.log(error);
                });
            } else {

                this.props.familyHistoryDirty(false);
                this.props.updateClickedAdd();
                this.props.setUpdateHistory();
            }
           
        }
        if (this.props.patientInactive) {
            //var form = document.getElementById("myForm");
            //var elements = form.elements;
            //for (var i = 0, len = elements.length; i < len; ++i) {
            //    elements[i].readOnly = true;
            //}

            document.querySelectorAll('#myForm input, #myForm select, #myForm button, #myForm textarea').forEach(elem => elem.disabled = true);
        }
        
    }

    onCalendarChange(date, name) {
        this.setState({
            [name]: date
        });
    }

    handleOptionChange(event) {

        const target = event.target;
        var value = null;

        let patientMedicalHistory = this.state.patientMedicalHistory;

        if (target.getAttribute("data-field") === 'flagYN') {
            if (patientMedicalHistory[target.getAttribute('name')].value === 'Yes' || (target.getAttribute('name') === 'OtherTypesofCancer' && patientMedicalHistory.OtherTypesofCancer.none !== 'Yes')) {
                value = patientMedicalHistory[target.getAttribute('name')][event.target.getAttribute("data-field")] === 1 ? 0 : 1;
                patientMedicalHistory[target.getAttribute('name')][event.target.getAttribute("data-field")] = value;

                if (!patientMedicalHistory[target.getAttribute('name')]['isDirty']) {
                    patientMedicalHistory[target.getAttribute('name')]['isDirty'] = true;
                }
            }
        } else {
            if (target.type === 'checkbox') {
                if (event.target.name === 'OtherTypesofCancer') {
                    if (event.target.getAttribute("data-field") === 'none' && target.checked) {
                        patientMedicalHistory.OtherTypesofCancer.ovarian = 'No';
                        patientMedicalHistory.OtherTypesofCancer.uterine = 'No';
                        patientMedicalHistory.OtherTypesofCancer.other = 'No';
                        patientMedicalHistory.OtherTypesofCancer.notes = null;
                        patientMedicalHistory.OtherTypesofCancer.typeofCancer = null;
                    } else if (event.target.getAttribute("data-field") !== 'none' && target.checked) {
                        patientMedicalHistory.OtherTypesofCancer.none = 'No';
                    }
                }
                value = target.checked ? "Yes" : "No";
            }
            else {
                value = target.value;
            }

            if (value === 'Yes' && patientMedicalHistory[event.target.name].autoFlagYN === 1) {
                patientMedicalHistory[event.target.name].flagYN = 1;
            } else if (value === 'No') {
                patientMedicalHistory[event.target.name].flagYN = 0;
            }

            patientMedicalHistory[event.target.name][event.target.getAttribute("data-field")] = value;

            if (!patientMedicalHistory[event.target.name]['isDirty']) {
                patientMedicalHistory[event.target.name]['isDirty'] = true;
            }
        }


        this.setState({
            patientMedicalHistory
        }, () => {
                this.isDirty = true;
        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = null;
        const name = target.name;

        if (target.type === 'checkbox') {
            value = target.checked;
        }
        else {
            value = target.value;
        }
        

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleDateChange = (date, dateName, field) => {

        let patientMedicalHistory = this.state.patientMedicalHistory;

        patientMedicalHistory[dateName][field] = date;

        if (!patientMedicalHistory[dateName]['isDirty']) {
            patientMedicalHistory[dateName]['isDirty'] = true;
        }

        this.setState({
            patientMedicalHistory
        }, () => {
            this.isDirty = true;
        });
    }

    handleSubmit = () => {

        this.setState({
            saving: true
        });

        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let kvp = this.state.patientMedicalHistory;

        kvp.OtherTypesofCancer.value = this.state.patientMedicalHistory.OtherTypesofCancer.none === 'Yes' ? "No" : "Yes";
        if (kvp.OtherTypesofCancer.value !== 'No') {
            let typeofCancer = '';
            if (kvp.OtherTypesofCancer.ovarian === 'Yes') {
                typeofCancer += 'Ovarian, ';
            }
            if (kvp.OtherTypesofCancer.uterine === 'Yes') {
                typeofCancer += 'Uterine, ';
            }
            if (kvp.OtherTypesofCancer.other) {
                typeofCancer += kvp.OtherTypesofCancer.notes ? kvp.OtherTypesofCancer.notes : '';
            }
            kvp.OtherTypesofCancer.typeofCancer = typeofCancer;
        } else {
            kvp.OtherTypesofCancer.typeofCancer = null;
        }


        if (kvp.Height.ft) {
            kvp.Height.value = kvp.Height.ft + "ft";
        }
        if (kvp.Height.in) {
            if (kvp.Height.value) {
                kvp.Height.value += kvp.Height.in
            } else {
                kvp.Height.value = kvp.Height.in
            }
        }

        let postdata = {
            medicalHistoryKVP: kvp,
            uniqueID: this.props.patient.patientID,
            userID: userInfo.userId
        }

        Axios.post(`/api/UpdatePatientMedicalHistory`, postdata
        ).then(response => {
            this.setState({
                saving: false
            });
            toast.success("Patient history has been updated");

            this.props.getPatientMedicalHistory();
        }).catch(error => {
            console.log(error);
            this.setState({
                saving: false
            });
        });
    }

    render() {
        return (
            <div>

                <Card className="pinkCard">
                    <Card.Header><span>Family History</span>
                    </Card.Header>
                    <Card.Body>

                        <div className="panel-content family-history-content">
                            <h4><strong><em>Answer the following</em></strong></h4>
                            <form id="myForm">
                                <div className="form-group col-12">
                                    <label className="form-label">Does the patient have a family history of breast cancer?  <span className="color-pink">*</span></label>

                                    <div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="FamilyHistoryOfBreastCancer" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.FamilyHistoryOfBreastCancer.value === "No"} />
                                            <span className="custom-control-label">No</span>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="FamilyHistoryOfBreastCancer" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.FamilyHistoryOfBreastCancer.value === "Yes"} />
                                            <span className="custom-control-label">Yes</span>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="FamilyHistoryOfBreastCancer" data-field="value" className="custom-control-input" value="N/A" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.FamilyHistoryOfBreastCancer.value === "N/A"} />
                                            <span className="custom-control-label">N/A</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.patientMedicalHistory.FamilyHistoryOfBreastCancer.value === "Yes" &&
                                    <div className="form-group col-12">
                                        <div className="row">
                                            <div className="form-group col-12">
                                            <label className="form-label">Mother &nbsp;
                                                {this.state.patientMedicalHistory.MotherHadBreastCancer.value === "Yes" &&
                                                   <i name="MotherHadBreastCancer" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.MotherHadBreastCancer.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                }
                                                </label>

                                                <div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="MotherHadBreastCancer" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.MotherHadBreastCancer.value === "No"} />
                                                        <span className="custom-control-label">No</span>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="MotherHadBreastCancer" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.MotherHadBreastCancer.value === "Yes"} />
                                                        <span className="custom-control-label">Yes</span>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="MotherHadBreastCancer" data-field="value" className="custom-control-input" value="Unknown" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.MotherHadBreastCancer.value === "Unknown"} />
                                                        <span className="custom-control-label">I don't know</span>
                                                    </div>
                                                </div>
                                                {this.state.patientMedicalHistory.MotherHadBreastCancer.value === "Yes" &&
                                                    <div className="row">
                                                        <div className="form-group col-3">
                                                            <label className="form-label">Age at Diagnosis</label>
                                                            <input type="text" className="form-control-custom" name="MotherHadBreastCancer" data-field="ageAtDiagnosis" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.MotherHadBreastCancer.ageAtDiagnosis} />
                                                        </div>
                                                        <div className="form-group col-9">
                                                            <label className="form-label">Notes</label>
                                                            <input type="text" className="form-control-custom" name="MotherHadBreastCancer" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.MotherHadBreastCancer.notes} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="form-group col-12">
                                            <label className="form-label">Sister
                                            &nbsp;
                                                {this.state.patientMedicalHistory.SisterHadBreastCancer.value === "Yes" &&
                                                    <i name="SisterHadBreastCancer" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.SisterHadBreastCancer.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                }
                                                </label>

                                                <div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="SisterHadBreastCancer" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.SisterHadBreastCancer.value === "No"} />
                                                        <span className="custom-control-label">No</span>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="SisterHadBreastCancer" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.SisterHadBreastCancer.value === "Yes"} />
                                                        <span className="custom-control-label">Yes</span>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="SisterHadBreastCancer" data-field="value" className="custom-control-input" value="Unknown" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.SisterHadBreastCancer.value === "Unknown"} />
                                                        <span className="custom-control-label">I don't know</span>
                                                    </div>
                                                </div>
                                                {this.state.patientMedicalHistory.SisterHadBreastCancer.value === "Yes" &&
                                                    <div className="row">
                                                        <div className="form-group col-3">
                                                            <label className="form-label">Age at Diagnosis</label>
                                                            <input type="text" className="form-control-custom" name="SisterHadBreastCancer" data-field="ageAtDiagnosis" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.SisterHadBreastCancer.ageAtDiagnosis} />
                                                        </div>
                                                        <div className="form-group col-9">
                                                            <label className="form-label">Notes</label>
                                                            <input type="text" className="form-control-custom" name="SisterHadBreastCancer" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.SisterHadBreastCancer.notes} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="form-group col-12">
                                            <label className="form-label">Daughter
                                            &nbsp;
                                                {this.state.patientMedicalHistory.DaughterHadBreastCancer.value === "Yes" &&
                                                    <i name="DaughterHadBreastCancer" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.DaughterHadBreastCancer.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                }
                                                </label>

                                                <div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="DaughterHadBreastCancer" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.DaughterHadBreastCancer.value === "No"} />
                                                        <span className="custom-control-label">No</span>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="DaughterHadBreastCancer" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.DaughterHadBreastCancer.value === "Yes"} />
                                                        <span className="custom-control-label">Yes</span>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="DaughterHadBreastCancer" data-field="value" className="custom-control-input" value="Unknown" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.DaughterHadBreastCancer.value === "Unknown"} />
                                                        <span className="custom-control-label">I don't know</span>
                                                    </div>
                                                </div>
                                                {this.state.patientMedicalHistory.DaughterHadBreastCancer.value === "Yes" &&
                                                    <div className="row">
                                                        <div className="form-group col-3">
                                                            <label className="form-label">Age at Diagnosis</label>
                                                            <input type="text" className="form-control-custom" name="DaughterHadBreastCancer" data-field="ageAtDiagnosis" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.DaughterHadBreastCancer.ageAtDiagnosis} />
                                                        </div>
                                                        <div className="form-group col-9">
                                                            <label className="form-label">Notes</label>
                                                            <input type="text" className="form-control-custom" name="DaughterHadBreastCancer" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.DaughterHadBreastCancer.notes} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="form-group col-12">
                                            <label className="form-label">Other
                                            &nbsp;
                                                {this.state.patientMedicalHistory.OtherHadBreastCancer.value === "Yes" &&
                                                    <i name="OtherHadBreastCancer" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.OtherHadBreastCancer.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                }
                                                </label>

                                                <div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="OtherHadBreastCancer" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherHadBreastCancer.value === "No"} />
                                                        <span className="custom-control-label">No</span>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="OtherHadBreastCancer" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherHadBreastCancer.value === "Yes"} />
                                                        <span className="custom-control-label">Yes</span>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" name="OtherHadBreastCancer" data-field="value" className="custom-control-input" value="Unknown" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherHadBreastCancer.value === "Unknown"} />
                                                        <span className="custom-control-label">I don't know</span>
                                                    </div>
                                                </div>
                                                {this.state.patientMedicalHistory.OtherHadBreastCancer.value === "Yes" &&
                                                    <div className="row">
                                                        <div className="form-group col-3">
                                                            <label className="form-label">Age at Diagnosis</label>
                                                            <input type="text" className="form-control-custom" name="OtherHadBreastCancer" data-field="ageAtDiagnosis" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.OtherHadBreastCancer.ageAtDiagnosis} />
                                                        </div>
                                                        <div className="form-group col-9">
                                                            <label className="form-label">Notes</label>
                                                            <input type="text" className="form-control-custom" name="OtherHadBreastCancer" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.OtherHadBreastCancer.notes} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                }
                            </form>
                        </div>

                    </Card.Body>
                </Card>

                <Card className="pinkCard">
                    <Card.Header><span>Patient Breast History</span>
                    </Card.Header>
                    <Card.Body>

                        <div className="panel-content family-history-content">
                            <h4><strong><em>Answer the following</em></strong></h4>
                            <form id="myForm">
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Has the patient had Breast Cancer? <span className="color-pink">*</span>
                                        &nbsp;
                                            {this.state.patientMedicalHistory.HaveYouHadBreastCancer.value === "Yes" &&
                                                <i name="HaveYouHadBreastCancer" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.HaveYouHadBreastCancer.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                            }
                                        </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="HaveYouHadBreastCancer" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.HaveYouHadBreastCancer.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="HaveYouHadBreastCancer" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.HaveYouHadBreastCancer.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="HaveYouHadBreastCancer" data-field="value" className="custom-control-input" value="N/A" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.HaveYouHadBreastCancer.value === "N/A"} />
                                                <span className="custom-control-label">N/A</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-12">
                                        {this.state.patientMedicalHistory.HaveYouHadBreastCancer.value === "Yes" &&
                                            <div className="form-group col-12">
                                                <div className="row">
                                                    <div className="form-group col-lg-4">
                                                        <label className="form-label">Laterality</label>
                                                        <select className="form-control-custom" name="HaveYouHadBreastCancer" data-field="laterality" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.HaveYouHadBreastCancer.laterality || ''}>
                                                            <option>Left</option>
                                                            <option>Right</option>
                                                            <option>Both</option>
                                                        </select>
                                                    </div>
                                            </div>

                                                <div className="row">
                                                    <div className="form-group col-lg-4">
                                                        <label className="form-label">Age at diagnosis?</label>
                                                        <input type="text" className="form-control-custom" name="HaveYouHadBreastCancer" data-field="ageAtDiagnosis" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.HaveYouHadBreastCancer.ageAtDiagnosis || ''} />
                                                    </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-lg-4">
                                                    <label className="form-label">Type of Breast Cancer</label>
                                                    <input type="text" className="form-control-custom" name="HaveYouHadBreastCancer" data-field="typeofCancer" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.HaveYouHadBreastCancer.typeofCancer || ''} />
                                                </div>
                                            </div>

                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <label className="form-label">Chemotherapy?</label>
                                                        <div className="form-control-custom no-border">
                                                        <div className="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" className="custom-control-input" data-field="value" name="Chemotherapy" value={this.state.patientMedicalHistory.Chemotherapy.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Chemotherapy.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.Chemotherapy.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" name="Chemotherapy" data-field="laterality" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.Chemotherapy.laterality || 'Left'} onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                                
                                                                    <DatePicker
                                                                selected={this.state.patientMedicalHistory?.Chemotherapy?.date && Moment(this.state.patientMedicalHistory.Chemotherapy.date).isValid() && Moment(this.state.patientMedicalHistory.Chemotherapy.date).toDate()}
                                                                        onChange={(date, dateName, field) => this.handleDateChange(date, "Chemotherapy", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                        customInput={
                                                                            <NumberFormat format="##/##/####" mask="_" />
                                                                        }
                                                                    />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                    <div className="col-lg-2">
                                                        <label className="form-label">Radiation Therapy?</label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" className="custom-control-input" data-field="value" name="RadiationTherapy" value={this.state.patientMedicalHistory.RadiationTherapy.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.RadiationTherapy.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.RadiationTherapy.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" name="RadiationTherapy" data-field="laterality" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.RadiationTherapy.laterality || 'Left'} onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.RadiationTherapy?.date && Moment(this.state.patientMedicalHistory.RadiationTherapy.date).isValid() && Moment(this.state.patientMedicalHistory.RadiationTherapy.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "RadiationTherapy", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">


                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Has the patient had breast surgery (including biopsy)?  <span className="color-pink">*</span></label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="HaveYouHadBreastSurgery" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.HaveYouHadBreastSurgery.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="HaveYouHadBreastSurgery" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.HaveYouHadBreastSurgery.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="HaveYouHadBreastSurgery" data-field="value" className="custom-control-input" value="N/A" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.HaveYouHadBreastSurgery.value === "N/A"} />
                                                <span className="custom-control-label">N/A</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-12">
                                        {this.state.patientMedicalHistory.HaveYouHadBreastSurgery.value === "Yes" &&
                                            <div className="form-group col-6">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                    <label className="form-label">Cyst Aspiration?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.CystAspiration.value === "Yes" &&
                                                            <i name="CystAspiration" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.CystAspiration.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="CystAspiration" value={this.state.patientMedicalHistory.CystAspiration.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.CystAspiration.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.CystAspiration.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.CystAspiration.laterality || 'Left'} data-field="laterality" name="CystAspiration" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.CystAspiration?.date && Moment(this.state.patientMedicalHistory.CystAspiration.date).isValid() && Moment(this.state.patientMedicalHistory.CystAspiration.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "CystAspiration", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                    <div className="col-lg-4">
                                                    <label className="form-label">Surgical Biopsy?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.SurgicalBiopsy.value === "Yes" &&
                                                            <i name="SurgicalBiopsy" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.SurgicalBiopsy.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="SurgicalBiopsy" value={this.state.patientMedicalHistory.SurgicalBiopsy.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.SurgicalBiopsy.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.SurgicalBiopsy.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.SurgicalBiopsy.laterality || ''} data-field="laterality" name="SurgicalBiopsy" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.SurgicalBiopsy?.date && Moment(this.state.patientMedicalHistory.SurgicalBiopsy.date).isValid() && Moment(this.state.patientMedicalHistory.SurgicalBiopsy.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "SurgicalBiopsy", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                    <div className="col-lg-4">
                                                    <label className="form-label">Needle Biopsy?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.NeedleBiopsy.value === "Yes" &&
                                                            <i name="NeedleBiopsy" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.NeedleBiopsy.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="NeedleBiopsy" value={this.state.patientMedicalHistory.NeedleBiopsy.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.NeedleBiopsy.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.NeedleBiopsy.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.NeedleBiopsy.laterality || 'Left'} data-field="laterality" name="NeedleBiopsy" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.NeedleBiopsy?.date && Moment(this.state.patientMedicalHistory.NeedleBiopsy.date).isValid() && Moment(this.state.patientMedicalHistory.NeedleBiopsy.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "NeedleBiopsy", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                    <div className="col-lg-4">
                                                    <label className="form-label">Mastectomy?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.Mastectomy.value === "Yes" &&
                                                            <i name="Mastectomy" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Mastectomy.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="Mastectomy" value={this.state.patientMedicalHistory.Mastectomy.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Mastectomy.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.Mastectomy.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Mastectomy.laterality || 'Left'} data-field="laterality" name="Mastectomy" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.Mastectomy?.date && Moment(this.state.patientMedicalHistory.Mastectomy.date).isValid() && Moment(this.state.patientMedicalHistory.Mastectomy.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "Mastectomy", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                    <div className="col-lg-4">
                                                    <label className="form-label">Lumpectomy?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.Lumpectomy.value === "Yes" &&
                                                            <i name="Lumpectomy" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Lumpectomy.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="Lumpectomy" value={this.state.patientMedicalHistory.Lumpectomy.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Lumpectomy.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.Lumpectomy.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Lumpectomy.laterality || 'Left'} data-field="laterality" name="Lumpectomy" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.Lumpectomy?.date && Moment(this.state.patientMedicalHistory.Lumpectomy.date).isValid() && Moment(this.state.patientMedicalHistory.Lumpectomy.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "Lumpectomy", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                    <div className="col-lg-4">
                                                    <label className="form-label">Implants?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.Implants.value === "Yes" &&
                                                            <i name="Implants" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Implants.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="Implants" value={this.state.patientMedicalHistory.Implants.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Implants.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.Implants.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Implants.laterality || 'Left'} data-field="laterality" name="Implants" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.Implants?.date && Moment(this.state.patientMedicalHistory.Implants.date).isValid() && Moment(this.state.patientMedicalHistory.Implants.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "Implants", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                    <div className="col-lg-4">
                                                    <label className="form-label">Reconstruction?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.Reconstruction.value === "Yes" &&
                                                            <i name="Reconstruction" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Reconstruction.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="Reconstruction" value={this.state.patientMedicalHistory.Reconstruction.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Reconstruction.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.Reconstruction.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Reconstruction.laterality || 'Left'} data-field="laterality" name="Reconstruction" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.Reconstruction?.date && Moment(this.state.patientMedicalHistory.Reconstruction.date).isValid() && Moment(this.state.patientMedicalHistory.Reconstruction.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "Reconstruction", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                    <div className="col-lg-4">
                                                    <label className="form-label">Reduction?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.Reduction.value === "Yes" &&
                                                            <i name="Reduction" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Reduction.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="Reduction" value={this.state.patientMedicalHistory.Reduction.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Reduction.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.Reduction.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Reduction.laterality || 'Left'} data-field="laterality" name="Reduction" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.Reduction?.date && Moment(this.state.patientMedicalHistory.Reduction.date).isValid() && Moment(this.state.patientMedicalHistory.Reduction.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "Reduction", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                    <div className="col-lg-4">
                                                    <label className="form-label">Other Breast Surgery?
                                                    &nbsp;
                                                        {this.state.patientMedicalHistory.OtherBreastSurgery.value === "Yes" &&
                                                            <i name="OtherBreastSurgery" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.OtherBreastSurgery.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                        }
                                                        </label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" data-field="value" name="OtherBreastSurgery" value={this.state.patientMedicalHistory.OtherBreastSurgery.value || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherBreastSurgery.value === "Yes" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>

                                                        {this.state.patientMedicalHistory.OtherBreastSurgery.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.OtherBreastSurgery.laterality || 'Left'} data-field="laterality" name="OtherBreastSurgery" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-12">
                                                                    <label className="form-label">Date</label>
                                                            
                                                            <DatePicker
                                                                selected={this.state.patientMedicalHistory?.OtherBreastSurgery?.date && Moment(this.state.patientMedicalHistory.OtherBreastSurgery.date).isValid() && Moment(this.state.patientMedicalHistory.OtherBreastSurgery.date).toDate()}
                                                                onChange={(date, dateName, field) => this.handleDateChange(date, "OtherBreastSurgery", "date")}
                                                                className="form-control-custom"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={
                                                                    <NumberFormat format="##/##/####" mask="_" />
                                                                }
                                                            />
                                                        </div>

                                                        <div className="form-group col-12">
                                                            <label className="form-label">Notes</label>
                                                            <input type="text" className="form-control-custom" value={this.state.patientMedicalHistory.OtherBreastSurgery.notes || ''} data-field="notes" name="OtherBreastSurgery" onChange={this.handleOptionChange} />
                                                        </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>



                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Has the patient had a previous mammogram? <span className="color-pink">*</span></label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PreviousMammogram" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PreviousMammogram.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PreviousMammogram" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PreviousMammogram.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PreviousMammogram" data-field="value" className="custom-control-input" value="N/A" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PreviousMammogram.value === "N/A"} />
                                                <span className="custom-control-label">N/A</span>
                                            </div>
                                        </div>
                                        {this.state.patientMedicalHistory.PreviousMammogram.value === "Yes" &&
                                            <div className="form-group col-12 mt-3">
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label className="form-label">Previous Mammogram Facility</label>
                                                    <input type="text" className="form-control-custom" value={this.state.patientMedicalHistory.PreviousMammogram.facility || ''} data-field="facility" name="PreviousMammogram" onChange={this.handleOptionChange} />
                                                </div>
                                                <div className="form-group col-6">
                                                    <div>
                                                        <label className="form-label">Date of last mammogram</label>
                                                    </div>
                                                    
                                                    <DatePicker
                                                        selected={this.state.patientMedicalHistory?.PreviousMammogram?.date && Moment(this.state.patientMedicalHistory.PreviousMammogram.date).isValid() && Moment(this.state.patientMedicalHistory.PreviousMammogram.date).toDate()}
                                                        onChange={(date, dateName, field) => this.handleDateChange(date, "PreviousMammogram", "date")}
                                                        className="form-control-custom"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <NumberFormat format="##/##/####" mask="_" />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </form>
                        </div>

                    </Card.Body>
                </Card>

                <Card className="pinkCard">
                    <Card.Header><span>Patient Breast Current</span>
                    </Card.Header>
                    <Card.Body>

                        <div className="panel-content family-history-content">
                            <h4><strong><em>Answer the following</em></strong></h4>
                            <form id="myForm">
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Is the patient having Breast problems?
                                         
                                        &nbsp;
                                            {this.state.patientMedicalHistory.PatientHasBreastProblems.value === "Yes" &&
                                                <i name="PatientHasBreastProblems" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.PatientHasBreastProblems.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                            }
                                        </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PatientHasBreastProblems" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PatientHasBreastProblems.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PatientHasBreastProblems" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PatientHasBreastProblems.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>

                                            {this.state.patientMedicalHistory.PatientHasBreastProblems.value === "Yes" &&

                                                <div className="form-group col-12 mt-3">

                                                <div className="row">
                                                    <div className="form-group col-12">
                                                        <label className="form-label">Breast Pain
                                                        &nbsp;
                                                            {this.state.patientMedicalHistory.BreastPain.value === "Yes" &&
                                                                <i name="BreastPain" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.BreastPain.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                            </label>

                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="BreastPain" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.BreastPain.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="BreastPain" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.BreastPain.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {(this.state.patientMedicalHistory.BreastPain.value === "Yes") &&
                                                            <Fragment>
                                                                <div className="form-group col-6 mt-3">
                                                                    <label className="form-label">Laterality</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.BreastPain.laterality || ''} data-field="laterality" name="BreastPain" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>
                                                            <div className="form-group col-6">
                                                            <label className="form-label">Focal</label>
                                                            <div className="form-control-custom no-border">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" className="custom-control-input" data-field="focalPainYN" name="BreastPain" value={this.state.patientMedicalHistory.BreastPain.focalPainYN || ''} onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.BreastPain.focalPainYN === "Yes" ? true : false} />
                                                                    <label className="custom-control-label"></label>
                                                                </div>
                                                            </div>
                                                                </div>
                                                                <div className="form-group col-6">
                                                                    <label className="form-label">Notes</label>
                                                                <input type="text" className="form-control-custom" name="BreastPain" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.BreastPain.notes || ''} />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                

                                                <div className="row">
                                                    <div className="form-group col-12">
                                                        <label className="form-label">Nipple Discharge
                                                        &nbsp;
                                                            {this.state.patientMedicalHistory.NippleDischarge.value === "Yes" &&
                                                                <i name="NippleDischarge" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.NippleDischarge.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                            </label>

                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="NippleDischarge" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.NippleDischarge.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="NippleDischarge" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.NippleDischarge.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {(this.state.patientMedicalHistory.NippleDischarge.value === "Yes") &&
                                                            <Fragment>
                                                                <div className="form-group col-6 mt-3">
                                                                    <label className="form-label">Laterality</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.NippleDischarge.laterality || ''} data-field="laterality" name="NippleDischarge" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                            </div>

                                                            <div className="form-group col-6">
                                                                <label className="form-label">Clear/Bloody</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.NippleDischarge.clearBloody || ''} data-field="clearBloody" name="NippleDischarge" onChange={this.handleOptionChange}>
                                                                    <option>Clear</option>
                                                                    <option>Bloody</option>
                                                                </select>
                                                            </div>
                                                                <div className="form-group col-6">
                                                                    <label className="form-label">Notes</label>
                                                                <input type="text" className="form-control-custom" name="NippleDischarge" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.NippleDischarge.notes || ''} />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="form-group col-12">
                                                        <label className="form-label">Lumps/Thickness
                                                        &nbsp;
                                                            {this.state.patientMedicalHistory.LumpsThickness.value === "Yes" &&
                                                                <i name="LumpsThickness" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.LumpsThickness.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                            </label>

                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="LumpsThickness" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.LumpsThickness.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="LumpsThickness" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.LumpsThickness.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {(this.state.patientMedicalHistory.LumpsThickness.value === "Yes") &&
                                                            <Fragment>

                                                            <div className="form-group col-6 mt-3">
                                                                <label className="form-label">Laterality</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.LumpsThickness.laterality || ''} data-field="laterality" name="LumpsThickness" onChange={this.handleOptionChange}>
                                                                    <option>Left</option>
                                                                    <option>Right</option>
                                                                    <option>Both</option>
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-lg-6 col-12">
                                                                <label className="form-label">Notes</label>
                                                                <input type="text" className="form-control-custom" name="LumpsThickness" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.LumpsThickness.notes || ''} />
                                                            </div>
                                                        </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                


                                                <div className="row">
                                                    <div className="form-group col-4">
                                                        <label className="form-label">Nipple Retraction
                                                        &nbsp;
                                                            {this.state.patientMedicalHistory.NippleRetraction.value === "Yes" &&
                                                                <i name="NippleRetraction" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.NippleRetraction.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                            </label>

                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="NippleRetraction" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.NippleRetraction.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="NippleRetraction" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.NippleRetraction.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {this.state.patientMedicalHistory.NippleRetraction.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.NippleRetraction.laterality || ''} data-field="laterality" name="NippleRetraction" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Is it new?</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.NippleRetraction.newYN || ''} data-field="newYN" name="NippleRetraction" onChange={this.handleOptionChange}>
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Notes</label>
                                                                <input type="text" className="form-control-custom" name="NippleRetraction" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.NippleRetraction.notes || ''} />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-4">
                                                        <label className="form-label">Other Problems 
                                                        &nbsp;
                                                            {this.state.patientMedicalHistory.OtherBreastProblems.value === "Yes" &&
                                                                <i name="OtherBreastProblems" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.OtherBreastProblems.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                        </label>


                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="OtherBreastProblems" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherBreastProblems.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="OtherBreastProblems" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherBreastProblems.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {this.state.patientMedicalHistory.OtherBreastProblems.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Laterality</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.OtherBreastProblems.laterality || ''} data-field="laterality" name="OtherBreastProblems" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Is it new?</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.OtherBreastProblems.newYN || ''} data-field="newYN" name="OtherBreastProblems" onChange={this.handleOptionChange}>
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Notes</label>
                                                                <input type="text" className="form-control-custom" name="OtherBreastProblems" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.OtherBreastProblems.notes || ''} />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Does the patient have Breast Markings?
                                        &nbsp;
                                            {this.state.patientMedicalHistory.PatientHasBreastMarkings.value === "Yes" &&
                                                <i name="PatientHasBreastMarkings" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.PatientHasBreastMarkings.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                            }
                                            </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PatientHasBreastMarkings" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PatientHasBreastMarkings.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PatientHasBreastMarkings" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PatientHasBreastMarkings.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>

                                            {this.state.patientMedicalHistory.PatientHasBreastMarkings.value === "Yes" &&
                                                <div className="form-group col-12 mt-3">
                                                <div className="row">
                                                    <div className="form-group col-4">
                                                        <label className="form-label">Moles
                                                        &nbsp;
                                                            {this.state.patientMedicalHistory.Moles.value === "Yes" &&
                                                                <i name="Moles" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Moles.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                            </label>

                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="Moles" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Moles.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="Moles" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Moles.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {this.state.patientMedicalHistory.Moles.value === "Yes" &&
                                                            <Fragment>
                                                            <div className="form-group col-lg-6 col-12 mt-3">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Moles.laterality || ''} data-field="laterality" name="Moles" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                            <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Is it new?</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Moles.newYN || ''} data-field="newYN" name="Moles" onChange={this.handleOptionChange}>
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Notes</label>
                                                                    <input type="text" className="form-control-custom" name="Moles" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.Moles.notes || ''} />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-4">
                                                        <label className="form-label">Scars
                                                        &nbsp;
                                                            {this.state.patientMedicalHistory.Scars.value === "Yes" &&
                                                                <i name="Scars" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Scars.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                            </label>

                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="Scars" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Scars.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="Scars" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Scars.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {this.state.patientMedicalHistory.Scars.value === "Yes" &&
                                                            <Fragment>
                                                            <div className="form-group col-lg-6 col-12 mt-3">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Scars.laterality || ''} data-field="laterality" name="Scars" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                            <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Is it new?</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Scars.newYN || ''} data-field="newYN" name="Scars" onChange={this.handleOptionChange}>
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </div>

                                                            <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Notes</label>
                                                                    <input type="text" className="form-control-custom" name="Scars" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.Scars.notes || ''} />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-4">
                                                        <label className="form-label">Bruises
                                                        &nbsp;
                                                        {this.state.patientMedicalHistory.Bruises.value === "Yes" &&
                                                                <i name="Bruises" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Bruises.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                            </label>

                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="Bruises" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Bruises.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="Bruises" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Bruises.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {this.state.patientMedicalHistory.Bruises.value === "Yes" &&
                                                            <Fragment>
                                                            <div className="form-group col-lg-6 col-12 mt-3">
                                                                    <label className="form-label">Laterality</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Bruises.laterality || ''} data-field="laterality" name="Bruises" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                            <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Is it new?</label>
                                                                    <select className="form-control-custom" value={this.state.patientMedicalHistory.Bruises.newYN || ''} data-field="newYN" name="Bruises" onChange={this.handleOptionChange}>
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </div>

                                                            <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Notes</label>
                                                                    <input type="text" className="form-control-custom" name="Bruises" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.Bruises.notes || ''} />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-4">
                                                        <label className="form-label">Other Markings
                                                        &nbsp;
                                                            {this.state.patientMedicalHistory.OtherBreastMarkings.value === "Yes" &&
                                                                <i name="OtherBreastMarkings" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.OtherBreastMarkings.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                                            }
                                                            </label>

                                                        <div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="OtherBreastMarkings" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherBreastMarkings.value === "No"} />
                                                                <span className="custom-control-label">No</span>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" name="OtherBreastMarkings" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherBreastMarkings.value === "Yes"} />
                                                                <span className="custom-control-label">Yes</span>
                                                            </div>
                                                        </div>
                                                        {this.state.patientMedicalHistory.OtherBreastMarkings.value === "Yes" &&
                                                            <Fragment>
                                                                <div className="form-group col-lg-6 col-12 mt-3">
                                                                    <label className="form-label">Laterality</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.OtherBreastMarkings.laterality || ''} data-field="laterality" name="OtherBreastMarkings" onChange={this.handleOptionChange}>
                                                                        <option>Left</option>
                                                                        <option>Right</option>
                                                                        <option>Both</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Is it new?</label>
                                                                <select className="form-control-custom" value={this.state.patientMedicalHistory.OtherBreastMarkings.newYN || ''} data-field="newYN" name="OtherBreastMarkings" onChange={this.handleOptionChange}>
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-lg-6 col-12">
                                                                    <label className="form-label">Notes</label>
                                                                <input type="text" className="form-control-custom" name="OtherBreastMarkings" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.OtherBreastMarkings.notes || ''} />
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                                //<div className="row">
                                                //    <div className="form-group col-12">
                                                //        <label className="form-label">For how long?</label>
                                                //        <input type="text" className="form-control-custom" value={this.state.patientMedicalHistory.OtherBreastMarkings.notes || ''} data-field="notes" name="OtherBreastMarkings" onChange={this.handleOptionChange} />
                                                //    </div>
                                                //</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </Card.Body>
                </Card>

                <Card className="pinkCard">
                    <Card.Header><span>Patient Medical Details</span>
                    </Card.Header>
                    <Card.Body>

                        <div className="panel-content personal-history-content">
                            <h4><strong><em>Answer the following</em></strong></h4>
                            <form id="myForm">
                                {/*<div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">No to All
                                        </label>

                                        <div>
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" name="noToAllMedicalDetails" className="custom-control-input" onChange={this.handleInputChange} checked={this.state.formFields.noToAllMedicalDetails} />
                                                <span className="custom-control-label"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Have you taken Hormone Replacement Therapy (HRT)?
                                        &nbsp;
                                            {this.state.patientMedicalHistory.Hormones.value === "Yes" &&
                                                <i name="Hormones" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.Hormones.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                            }
                                            </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="Hormones" data-field="value" className="custom-control-input" value="never" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Hormones.value === "never"} />
                                                <span className="custom-control-label">Never</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="Hormones" data-field="value" className="custom-control-input" value="stoppedMore5" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Hormones.value === "stoppedMore5"} />
                                                <span className="custom-control-label">Stopped use 5 or more years ago</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="Hormones" data-field="value" className="custom-control-input" value="stoppedLess5" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Hormones.value === "stoppedLess5"} />
                                                <span className="custom-control-label">Stopped use less than 5 years ago</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="Hormones" data-field="value" className="custom-control-input" value="current" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Hormones.value === "current"} />
                                                <span className="custom-control-label">Current User</span>
                                            </div>
                                        </div>
                                    </div>

                                    {(this.state.patientMedicalHistory.Hormones.value === "stoppedLess5" || this.state.patientMedicalHistory.Hormones.value === "current") &&
                                        <Fragment>
                                            <div className="form-group col-lg-3 col-12">
                                                <label className="form-label">Estrogen only or combined treatment?</label>
                                            <select className="form-control-custom" value={this.state.patientMedicalHistory.Hormones.typeofHRT || ''} data-field="typeofHRT" name="Hormones" onChange={this.handleOptionChange}>
                                                    <option />
                                                    <option>Estrogen Only</option>
                                                    <option>Estrogen and Progesterone</option>
                                                    <option>Unknown</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-lg-3 col-12">
                                                <label className="form-label">Length of HRT Use?</label>
                                            <select className="form-control-custom" value={this.state.patientMedicalHistory.Hormones.lengthofHRTUse || ''} data-field="lengthofHRTUse" name="Hormones" onChange={this.handleOptionChange}>
                                                <option />
                                                    <option>1 Year</option>
                                                    <option>2 Years</option>
                                                    <option>3 Years</option>
                                                    <option>4 Years</option>
                                                    <option>5 Years</option>
                                                    <option>6 Years</option>
                                                    <option>+6 Years</option>
                                                    <option>Unknown</option>
                                                </select>
                                        </div>
                                            {this.state.patientMedicalHistory.Hormones.value === "stoppedLess5" &&
                                                <div className="form-group col-lg-3 col-12">
                                                    <label className="form-label">How long has it been since you used HRT?</label>
                                            <select className="form-control-custom" value={this.state.patientMedicalHistory.Hormones.timeSinceHRTUse || ''} data-field="timeSinceHRTUse" name="Hormones" onChange={this.handleOptionChange}>
                                                <option />
                                                        <option>0 Years</option>
                                                        <option>1 Year</option>
                                                        <option>2 Years</option>
                                                        <option>3 Years</option>
                                                        <option>4 Years</option>
                                                        <option>5 Years</option>
                                                        <option>Unknown</option>
                                                    </select>
                                                </div>
                                        }

                                        {this.state.patientMedicalHistory.Hormones.value === "current" &&
                                            <div className="form-group col-lg-3 col-12">
                                            <label className="form-label">How long to do intend to use HRT?</label>
                                            <select className="form-control-custom" value={this.state.patientMedicalHistory.Hormones.intendedLengthofHRTUse || ''} data-field="intendedLengthofHRTUse" name="Hormones" onChange={this.handleOptionChange}>
                                                <option />
                                                    <option>1 Year</option>
                                                    <option>2 Years</option>
                                                    <option>3 Years</option>
                                                    <option>4 Years</option>
                                                    <option>5 Years</option>
                                                    <option>6 Years</option>
                                                    <option>+6 Years</option>
                                                    <option>Unknown</option>
                                                </select>
                                            </div>
                                        }
                                        </Fragment>
                                    }
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Is the patient taking oral contraceptives?
                                        &nbsp;
                                            {this.state.patientMedicalHistory.OralContraceptives.value === "Yes" &&
                                                <i name="OralContraceptives" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.OralContraceptives.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                            }
                                            </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="OralContraceptives" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OralContraceptives.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="OralContraceptives" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OralContraceptives.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-lg-6 col-12">
                                        <div>
                                            <label className="form-label">When was the patient's last menstrual period?</label>
                                        </div>
                                        
                                        <DatePicker
                                            selected={this.state.patientMedicalHistory?.DateofLastMenstrualPeriod?.date !== null && Moment(this.state.patientMedicalHistory.DateofLastMenstrualPeriod.date).isValid() && Moment(this.state.patientMedicalHistory.DateofLastMenstrualPeriod.date).toDate()}
                                            onChange={(date, dateName, field) => this.handleDateChange(date, "DateofLastMenstrualPeriod", "date")}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Is the patient pregnant?
                                        &nbsp;
                                            {this.state.patientMedicalHistory.PatientPregnant.value === "Yes" &&
                                                <i name="PatientPregnant" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.PatientPregnant.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                            }
                                            </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PatientPregnant" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PatientPregnant.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="PatientPregnant" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.PatientPregnant.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Has the patient had other type(s) of cancer?
                                        &nbsp;
                                            {this.state.patientMedicalHistory.OtherTypesofCancer.value === "Yes" &&
                                                <i name="OtherTypesofCancer" data-field="flagYN" className={"fas fa-flag " + (this.state.patientMedicalHistory.OtherTypesofCancer.flagYN === 1 ? 'color-pink' : 'color-light-grey')} onClick={this.handleOptionChange}></i>
                                            }
                                            </label>

                                        <div>
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" name="OtherTypesofCancer" data-field="none" className="custom-control-input"  onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherTypesofCancer.none === "Yes"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" name="OtherTypesofCancer" data-field="ovarian" className="custom-control-input"  onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherTypesofCancer.ovarian === "Yes"} />
                                                <span className="custom-control-label">Ovaries</span>
                                            </div>
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" name="OtherTypesofCancer" data-field="uterine" className="custom-control-input" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherTypesofCancer.uterine === "Yes"} />
                                                <span className="custom-control-label">Uterus</span>
                                            </div>
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" name="OtherTypesofCancer" data-field="other" className="custom-control-input" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.OtherTypesofCancer.other === "Yes"} />
                                                <span className="custom-control-label">Other</span>
                                            </div>

                                            {this.state.patientMedicalHistory.OtherTypesofCancer.other === "Yes" &&
                                                <div className="form-group col-12 mt-3">
                                                    <div className="row">
                                                    <div className="form-group col-12">
                                                        <label className="form-label">Explain</label>
                                                        <input type="text" className="form-control-custom" name="OtherTypesofCancer" data-field="notes" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.OtherTypesofCancer.notes || ''} />
                                                    </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    
                                    <div className="col-lg-1 col-sm-3">
                                        <div>
                                            <label className="form-label">Feet</label>
                                        </div>
                                        <input type="number" className="form-control-custom" name="Height" data-field="ft" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.Height.ft || ''} />
                                    </div>
                                    <div className="col-lg-1 col-sm-3">
                                        <div>
                                            <label className="form-label">Inches</label>
                                        </div>
                                        <input type="number" className="form-control-custom" name="Height" data-field="in" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.Height.in || ''} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-lg-2 col-sm-4">
                                        <div>
                                            <label className="form-label">Weight</label>
                                        </div>
                                        <input type="number" className="form-control-custom" name="Weight" data-field="value" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.Weight.value || ''} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-lg-2 col-sm-4">
                                        <div>
                                            <label className="form-label">What was your age at the time of your first menstrual period?</label>
                                        </div>
                                        <input type="number" className="form-control-custom" name="AgeatFirstMenstrualPeriod" data-field="value" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.AgeatFirstMenstrualPeriod.value || ''} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Have you given birth to one or more children?
                                        </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="GivenBirth" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.GivenBirth.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="GivenBirth" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.GivenBirth.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.patientMedicalHistory.GivenBirth.value === 'Yes' &&
                                        <div className="form-group col-lg-3 col-sm-5 ml-3">
                                            <div>
                                                <label className="form-label">How old were you when you delivered your first child?</label>
                                            </div>
                                        <input type="number" className="form-control-custom" name="GivenBirth" data-field="ageAtDiagnosis" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.GivenBirth.ageAtDiagnosis || ''} />
                                        </div>
                                    }
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Have you gone through menopause (1 year after last menstrual cycle)?
                                        </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="Menopause" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Menopause.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="Menopause" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.Menopause.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.patientMedicalHistory.Menopause.value === 'Yes' &&
                                        <div className="form-group col-lg-3 col-sm-5 ml-3">
                                            <div>
                                                <label className="form-label">At what age was menopause? </label>
                                            </div>
                                        <input type="number" className="form-control-custom" name="Menopause" data-field="ageAtDiagnosis" onChange={this.handleOptionChange} value={this.state.patientMedicalHistory.Menopause.ageAtDiagnosis || ''} />
                                        </div>
                                    }
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Do you have a genetic mutation that puts you at a higher risk for developing breast cancer?
                                        </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="GeneticMutation" data-field="value" className="custom-control-input" value="unknown" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.GeneticMutation.value === "unknown"} />
                                                <span className="custom-control-label">Unknown</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="GeneticMutation" data-field="value" className="custom-control-input" value="testedAndNormal" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.GeneticMutation.value === "testedAndNormal"} />
                                                <span className="custom-control-label">tested and normal</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="GeneticMutation" data-field="value" className="custom-control-input" value="brca1+" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.GeneticMutation.value === "brca1+"} />
                                                <span className="custom-control-label">BRCA1+</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="GeneticMutation" data-field="value" className="custom-control-input" value="brca2+" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.GeneticMutation.value === "brca2+"} />
                                                <span className="custom-control-label">BRCA2+</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="form-label">Do you have Ashkenazi Jewish ancestry?
                                        </label>

                                        <div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="AJAncestry" data-field="value" className="custom-control-input" value="No" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.AJAncestry.value === "No"} />
                                                <span className="custom-control-label">No</span>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" name="AJAncestry" data-field="value" className="custom-control-input" value="Yes" onChange={this.handleOptionChange} checked={this.state.patientMedicalHistory.AJAncestry.value === "Yes"} />
                                                <span className="custom-control-label">Yes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </Card.Body>
                </Card>

                {!this.props.patientInactive &&
                    <>
                        {this.state.saving ?
                            <div className="form-group col-12">
                                <ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} />
                            </div>
                            :
                            <button className="btn btn-submit" onClick={this.handleSubmit}>Save</button>
                        }
                    </>
                }
            </div>
        );
    }
}

export default withRouter(FamilyHistoryTab);