import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { useForm } from "react-hook-form";
import { getDocumentTypesByLevel } from '../../common/services/ApiService';

import { useDropzone } from 'react-dropzone';
import { InboxOutlined } from '@ant-design/icons';
import { insertPatientOrExamDocument } from '../../common/services/DocumentService';

import { toast } from '@rickylandino/react-messages';

import ReactLoading from 'react-loading';

function MyDropzone(props) {
    const maxSize = 1048576;

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()
        let file = acceptedFiles[0];

        reader.onload = async function () {
            props.setFile(file);
            //props.addFile(file);

        }
        reader.readAsArrayBuffer(file);
    }, [])

    const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
        onDrop,
        minSize: 0,
        accept: 'application/pdf'
    });

    const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

    return (
        <div className="ant-upload ant-upload-drag">
            <div {...getRootProps()} className="pt-4 h-100">
                <input {...getInputProps()} />
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                {!isDragActive && 'Click here to add an attachment'}
                {isDragActive && !isDragReject && "Drop the file!"}
                {isDragReject && "File type not accepted, sorry!"}
                {isFileTooLarge && (
                    <div className="text-danger mt-2">
                        File is too large.
                    </div>
                )}
            </div>
        </div>
    );

}

function AddAttachmentPane(props) {
    const [showPane, setShowPane] = useState(true);
    const [file, setFile] = useState({});
    const [documentTypes, setDocumentTypes] = useState([]);

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, setValue, getValues } = useForm();

    const theme = window.sessionStorage.getItem("theme");

    useEffect(() => {

        getDocumentTypesByLevel(props.level).then(data => {
            setDocumentTypes(data);
        });

    }, []);

    function hidePane() {
        setShowPane(false);

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    function onSubmit(documentInfo) {
        setLoading(true);

        documentInfo.documentLevel = props.level;
        documentInfo.entityID = props.level === 'Patient' ? props.patient.patientID : props.examID;

        insertPatientOrExamDocument(file, documentInfo).then(data => {
            props.updatePatientAttachments(data);

            setLoading(false);
            hidePane();

            toast.success(`${documentInfo.documentName} has been uploaded`);
        }).catch(error => {
            setLoading(false);

            toast.error(`${documentInfo.documentName} could not be uploaded at this time.`);
        });

    }

    function handleFile(file) {
        setFile(file);
        if (!getValues("documentName")) {
            let fileName = file?.name.split(".")[0];
            setValue("documentName", fileName);
        }
    }

    return (
        <SlidingPane
            overlayClassName='pinkCard'
            className={theme === 'dark' ? "dark-theme smallPane" : 'light-theme smallPane'}
            isOpen={showPane || false}
            /*title={<div style={{ height: '50px' }}><span style={{marginTop: '15px', position: 'fixed' }}>Add a Candidate</span> <button className="btn btn-submit float-right">Submit</button></div>}*/
            title='Add Attachment'
            onRequestClose={hidePane}
            shouldCloseOnEsc={false}
        >

            <div className="slide-pane-body">
                <MyDropzone setFile={(file) => handleFile(file)} />
                {file?.name && <pre className="mb-4">{file.name}</pre>}

                <form>

                    <div className="row">
                        <div className="form-group col-12">
                            <label className="form-label">Document Name</label>
                            <input {...register("documentName", { required: true })} className="form-control-custom" />
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Document Type</label>
                            <select className="form-control-custom" {...register("documentType", { required: true })}>
                                <option></option>
                                {documentTypes.map((doc, idx) => <option key={idx} value={doc.documentType}>{doc.documentType}</option>)}
                            </select>
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Notes</label>
                            <textarea {...register("notes")} className="form-control-custom" />
                        </div>
                    </div>
                </form>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit" onClick={handleSubmit(onSubmit)} disabled={loading}>{loading ? <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#F30079'} height={30} width={30} /> : 'Add Attachment'}</button>
                </div>
            </div>
        </SlidingPane>
    );
}

export default withRouter(AddAttachmentPane);