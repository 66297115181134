import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { useForm, Controller  } from "react-hook-form";

import { Select, Table, Input } from 'antd';

import { getAllFacilities, isUsernameUnique, isEmailUnique, getUserInformationByID, updateUser } from '../../common/services/ApiService';
import { createUser } from '../../common/services/AuthService';

import { toast } from '@rickylandino/react-messages';
import NumberFormat from 'react-number-format';
import { allUsers } from '../../common/services/ReportService';

const { Search } = Input;

function ManageUser(props) {
    const [showPane, setShowPane] = useState(true);
    const [allFacilities, setAllFacilities] = useState([]);
    const [filteredFacilities, setFilteredFacilities] = useState([]);

    const theme = window.sessionStorage.getItem("theme");

    const { register, handleSubmit, setValue, getValues, watch, setError, clearErrors,
        formState: { errors }, control    } = useForm();

    const watchAdmin = watch('isAdmin', false);
    const watchAllAccess = watch('allFacilityAccess', false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);

    const [loaded, setLoaded] = useState(false);

    const onSubmit = user => {
        if (props.userID) {
            user.isLDAP = false;
            if (!user.facilities?.length > 0 && !user.isAdmin) {
                toast.error("Please fill in all required information");
            } else {
                updateUser(user).then(data => {
                    toast.success(`${user.firstName} ${user.lastName} has been updated`);

                    allUsers.next(data);

                    hidePane();
                });
            }
        } else {
            user.isLDAP = true;

            if (!user.facilities?.length > 0 && !user.isAdmin) {
                toast.error("Please fill in all required information");
            } else {
                createUser(user).then(data => {
                    toast.success(`${user.firstName} ${user.lastName} has been added`);

                    if (props.from === 'manageFacility') {

                        let u = {
                            fName: user.firstName,
                            lName: user.lastName,
                            userID: data
                        };

                        setTimeout(() => {
                            props.hidePane(u);
                        }, 1000);
                    }

                    hidePane();
                });
            }
        }
    }

    const { Option } = Select;

    useEffect(() => {
        if (props.userID) {
            getUserInformationByID(props.userID).then(data => {
                register("userID");
                setSelectedRowKeys(data.facilities.map(fac => fac.id));
                setSelectedFacilities(data.facilities);
                //setValue("facilities", data.facilities);

                setValue("userID", props.userID);
                setValue("firstName", data.user?.fName);
                setValue("lastName", data.user?.lName);
                setValue("username", data.user?.username);
                setValue("email", data.user?.email);
                setValue("cellPhone", data.user?.cellPhone);
                setValue("isAdmin", data.user?.adminYN ? true : false);
                setValue("isTech", data.user?.technologistYN ? true : false);
                setValue("isLdap", data.isLdapUser ? true : false);
                setValue("isInactive", data.user?.inactiveYN ? true : false);
                setValue("allFacilityAccess", data.user?.allFacilityAccess ? true : false);
                setValue("managerYN", data.user?.managerYN ? true : false);

                setLoaded(true);
            });
        } else {
            setValue("isLdap", true);
            setLoaded(true);
        }
    }, [])

    useEffect(() => {

        getAllFacilities().then(data => {
            setAllFacilities(data);
            setFilteredFacilities(data);
        });

    }, [register]);

    useEffect(() => {
        setValue("facilities", selectedFacilities);
    }, [selectedFacilities]);

    function hidePane() {
        setShowPane(false);

        if (props.from !== 'manageFacility') {
            setTimeout(() => {
                props.hidePane();
            }, 1000)
        }
        
    }

    const onError = (errors, e) => {
        toast.error("Please fill in all required information");
    }

    function checkValidUsername() {
        if (getValues().username) {
            isUsernameUnique(getValues().username).then(data => {
                if (!data) {
                    setError("username", {
                        type: "manual",
                        message: "This username is already taken.",
                    });
                } else {
                    clearErrors("username");
                }
            })
        } else {
            clearErrors("username");
        }
        
    }

    function checkValidEmail() {
        if (getValues().email) {
            isEmailUnique(getValues().email).then(data => {
                if (!data) {
                    setError("email", {
                        type: "manual",
                        message: "This email is already taken.",
                    });
                } else {
                    clearErrors("email");
                }
            })
        } else {
            clearErrors("email");
        }
    }

    const onSelectChange = (selectedRow, selected, selectedRows) => {
        let foundIdx = selectedRowKeys.findIndex(id => id === selectedRow.facilityID);
        var selRowKeys;
        if (foundIdx >= 0) {
            selRowKeys = selectedRowKeys.filter(id => id !== selectedRow.facilityID);
        } else {
            selRowKeys = [...selectedRowKeys, selectedRow.facilityID];
        }

        setSelectedRowKeys(selRowKeys);

        handleRadioChange(selected, selectedRow);
    };

    const onSelectAllChange = (selected, selectedRows, changeRows) => {
        setSelectedRowKeys(selected ? selectedRows : []);
    }

    function handleRadioChange(checked, record, updateTech) {

        let sf = [...selectedFacilities];
        let foundIndex = sf.findIndex(fac => fac.id === record.facilityID);

        if (checked) {
            let userFac = {
                id: record.facilityID
            }

            if (updateTech) {
                userFac.techOverride = 1;
            }

            if (foundIndex >= 0) {
                sf[foundIndex] = userFac;
            } else {
                sf.push(userFac);
                setSelectedRowKeys([...selectedRowKeys, record.facilityID]);
            }

        } else {
            if (updateTech) {
                if (foundIndex >= 0) {
                    sf[foundIndex].techOverride = 0;
                }
            } else {
                sf.splice(foundIndex, 1);
            }
            
        }

        setSelectedFacilities(sf);
    }

    function renderFacilityCheckbox(text, record) {



        const checked = selectedFacilities.find(fac => fac.id === record.facilityID)?.techOverride === 1;

        return (
            <div className="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" className="custom-control-input" checked={checked} onChange={(e) => handleRadioChange(e.target.checked, record, true)} />
                <label className="custom-control-label"></label>
            </div>
        )
    }

    const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectChange,
        hideSelectAll: true
    };

    const facilityColumns = [
        {
            dataIndex: 'facilityNumber',
            title: 'Facility Number',
        }, {
            dataIndex: 'facilityName',
            title: 'Facility Name'
        }, {
            dataIndex: 'checkbox',
            title: 'Is Tech?',
            render: renderFacilityCheckbox
        }
    ];

    const onSearch = (value) => {
        let filteredValue = allFacilities.filter(fac => fac.facilityNumber.toLowerCase().includes(value.toLowerCase()) || fac.facilityName.toLowerCase().includes(value.toLowerCase()));
        setFilteredFacilities(filteredValue);
    };

    return (
        <SlidingPane
            overlayClassName='pinkCard'
            className={theme === 'dark' ? "dark-theme lgPane" : 'light-theme lgPane'}
            isOpen={showPane || false}
            /*title={<div style={{ height: '50px' }}><span style={{marginTop: '15px', position: 'fixed' }}>Add a Candidate</span> <button className="btn btn-submit float-right">Submit</button></div>}*/
            title={props.userID ? 'Manage User' : 'Add User'}
            onRequestClose={hidePane}
            shouldCloseOnEsc={false}
        >

            <div className="slide-pane-body">
                <form>
                    {loaded &&
                        <div className="row">
                            <div className="form-group col-12">
                                <label className="form-label">First Name <span className="color-pink">*</span></label>
                                <input {...register("firstName", { required: true })} className="form-control-custom" autoComplete="off" type="text" />
                            </div>

                            <div className="form-group col-12">
                                <label className="form-label">Last Name <span className="color-pink">*</span></label>
                                <input {...register("lastName", { required: true })} className="form-control-custom" autoComplete="off" />
                            </div>

                            <div className="form-group col-12">
                                <label className="form-label">Username <span className="color-pink">*</span></label>
                                <input {...register("username", { required: true })} className="form-control-custom" onBlur={checkValidUsername} />
                                {errors.username && <pre className="text-danger mb-0 ml-3">{errors.username.message}</pre>}
                            </div>


                            <div className="form-group col-12">
                                <label className="form-label">Email <span className="color-pink">*</span></label>
                                <input {...register("email", {
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email'
                                    }, required: true
                                })} className="form-control-custom" onBlur={checkValidEmail} />
                                {errors.email && <pre className="text-danger mb-0 ml-3">{errors.email.message}</pre>}
                            </div>

                            {/*<div className="form-group col-12">*/}
                            {/*    <label className="form-label">Cell Phone</label>*/}
                            {/*    <NumberFormat className="form-control-custom" {...register("cellPhone")} defaultValue={getValues().cellPhone} format="(###) ###-####" mask="_" onValueChange={(val) => setValue("cellPhone", val.formattedValue)}/>*/}
                            {/*</div>*/}

                            <div className="form-group col-12">
                                <label className="form-label">Cell Phone</label>
                                <Controller
                                    name="cellPhone"
                                    control={control}
                                    defaultValue={getValues().cellPhone}
                                    render={({ field }) => <NumberFormat {...field} className="form-control-custom" format="(###) ###-####" mask="_" onValueChange={(val) => setValue("cellPhone", val.formattedValue)} />}
                                />
                                {/*<NumberFormat className="form-control-custom" {...register("cellPhone")} defaultValue={getValues().cellPhone} format="(###) ###-####" mask="_" onValueChange={(val) => setValue("cellPhone", val.formattedValue)}/>*/}
                            </div>

                            <div className="form-group col-4">
                                <label className="form-label">Admin</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" {...register("isAdmin")} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-4">
                                <label className="form-label">Technologist</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" {...register("isTech")} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-4">
                                <label className="form-label">LDAP User</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" {...register("isLdap")} disabled />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-4">
                                <label className="form-label">Inactive</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" {...register("isInactive")} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                        </div>

                        <div className="form-group col-4">
                            <label className="form-label">All Facility Access</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("allFacilityAccess")} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-4">
                            <label className="form-label">Manager</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("managerYN")} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>

                            {(!watchAdmin && !watchAllAccess) ?
                                <>
                                <div className="form-group col-12">
                                    <label className="form-label">Facilities <span className="color-pink">*</span></label>

                                        <Search
                                            placeholder="Search For A Facility & Press Enter"
                                            onSearch={onSearch}
                                        />

                                    {/*<Select*/}
                                    {/*    name="facilities"*/}
                                    {/*    mode="multiple"*/}
                                    {/*    maxTagCount={1}*/}
                                    {/*    onChange={modifyFacilities}*/}
                                    {/*    allowClear*/}
                                    {/*    placeholder="Please select 1 or more"*/}
                                    {/*    className="form-control-custom w-100"*/}
                                    {/*    bordered={false}*/}
                                    {/*    defaultValue={getValues().facilities}*/}
                                    {/*    filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}*/}
                                    {/*>*/}
                                    {/*    {allFacilities.map((fac, idx) => <Option key={idx} value={fac.facilityID}>{fac.facilityNumber && fac.facilityNumber + " - "}{fac.facilityName}</Option>)}*/}
                                    {/*</Select>*/}

                                    </div>
                                    <div className="col-12">
                                    <Table
                                        rowKey={(record) => record.facilityID}
                                        dataSource={filteredFacilities}
                                        columns={facilityColumns}
                                        rowSelection={rowSelection}
                                        size='small'
                                        />
                                    </div>
                                </>
                                :
                                <div className="form-group col-12">
                                    <p>Note: As an Admin, this user will have access to all facilities</p>
                                </div>
                            }


                        </div>
                    }
                </form>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit" onClick={handleSubmit(onSubmit, onError)} disabled={(errors.username || errors.email) ? true : false}>{props.userID ? 'Update User' : 'Add User'}</button>
                </div>
            </div>
        </SlidingPane>
    );
}

export default withRouter(ManageUser);