import React, { useEffect, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import { toast } from '@rickylandino/react-messages';
import { insertWhatsNewPost, getMostRecentWhatsNewPost, getWhatsNewPostByID } from '../../common/services/WhatsNewService';

function UpdatePost(props) {

    const [editorInfo, setEditorInfo] = useState({
        editorContent: "",
        templateID: null
    });

    const [post, setPost] = useState({});

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        if (props.id) {
            getWhatsNewPostByID(props.id).then(data => {
                if (data) {
                    setPost(data);
                    setEditorInfo({
                        editorContent: data.htmlContent,
                        templateID: Math.random()
                    });
                }
            });
        }
    }, []);

    function handleEditorChange(content, editor) {
        setEditorInfo({
            ...editorInfo,
            editorContent: content
        });
    }

    function toggleActivated(e) {
        let value = e.target.value;

        setPost({
            ...post,
            active: e.target.checked
        });
    }

    function handleSubmit() {

        let postdata = {};
        let successMessage = "";

        if (post.whatsNewID) {
            postdata = post;
            postdata.updatedBy = userInfo.userId;
            successMessage = "Post has been updated";
        } else {
            postdata.publishedBy = userInfo.userId;
            successMessage = "Post has been created";
        }

        postdata.htmlContent = editorInfo.editorContent;

        insertWhatsNewPost(postdata).then(data => {
            if (data) {
                toast.success(successMessage);

                props.backToPosts();
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    return (
        <>
            <div className="form-group col-8 mx-auto">
                <label className="form-label">Content</label>
                <Editor
                    onEditorChange={handleEditorChange}
                    initialValue={editorInfo.editorContent} //This tricks the component to rerendering with correct content as template selection changes
                    key={editorInfo.templateID}
                    init={{
                        height: 500,
                        selector: 'textarea',
                        image_title: true,
                        //images_upload_handler: example_image_upload_handler,
                        image_uploadtab: true,
                        plugins: 'advlist autolink lists link charmap print preview hr anchor pagebreak code table',
                        branding: false,
                        content_style: "body { line-height: 1; }",
                        toolbar: 'undo redo | styleselect | bold italic numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent | code table',
                        setup: function (editor,) {

                        }
                    }}
                />
            </div>

            <div className="form-group col-8">
                <div className="form-group col-lg-6">
                    <label className="form-label">Is Active</label>
                    <div className="form-control-custom no-border">
                        <div className="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" className="custom-control-input" value={post.active} onChange={toggleActivated} checked={post.active} />
                            <label className="custom-control-label"></label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group col-12 padding-25-10 mt-4">
                <button className="btn btn-submit" onClick={handleSubmit}>{post.whatsNewID ? 'Update' : 'Add'}</button>
            </div>
        </>
    );
}

export default UpdatePost;