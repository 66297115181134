import React from "react";
import { withRouter } from "react-router-dom";
import cookie from 'react-cookies';
import Axios from '../../config/axios';

import { toast } from '@rickylandino/react-messages';

class TFALogin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formFields: {
                tfaInput: '',
                trustdevice: false
            },
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    verify2FA = async (event) => {
        event.preventDefault();
        let validCode = false;

        let postdata = {};
        postdata.userid = this.props.userid.toString();
        //postdata.password = this.props.password.toString();
        postdata.totpcode = this.state.formFields.tfaInput;

        await Axios.post(`/api/Verify2FA`, postdata
        ).then(response => {
            validCode = response.data;
        }).catch(error => {
            console.log(error);
        });

        if (validCode) {
            const timeInSeconds = 15780000 //6 months;
            const expires = new Date();
            expires.setDate(Date.now() + timeInSeconds * 60 * 60 * 24 * 14);
            if (this.state.formFields.trustdevice === true) {
                cookie.save('bypass2FA' + this.props.userid, true, { path: '/', maxAge: timeInSeconds });
            }

            //this.props.validateUser();

            let path = '/worklist';
            this.props.history.push(path);
        }
        else {

            toast.error("Invalid 2FA Code.");
        }
    }

    goLogin = () => {
        this.props.goLogin();
    }

    render() {

        return (
            <div className="slidePaneContent height100">
                <form id="login-form" className="" autoComplete="off">
                    <header>
                        Enter Code From Your Mobile Device
                    </header>
                    <fieldset>
                        <div className="form-group text-left">
                            <input type="hidden" value="" />
                            <div className="">
                                <label className="form-label">Enter the security code from the authenticator app on your mobile device</label>
                                <input autoFocus className="form-control" name="tfaInput" onChange={this.handleInputChange} value={this.state.formFields.tfaInput} />
                                <span className="help-block">
                                    6-digit code
                            </span>
                            </div>
                        </div>
                        <div className="form-group text-left">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" name="trustdevice" id="trustdevice" value={this.state.formFields.trustdevice} onChange={this.handleInputChange} />
                                <label className="custom-control-label" htmlFor="rememberme"> Trust this device. Security code will not be requested for 6 months on this device.</label>
                            </div>
                        </div>
                    </fieldset>
                    <div className="row">
                        <div className="form-group col-12 text-right">
                            <button className="btn btn-default" onClick={(e) => { this.verify2FA(e); }}>Secure login</button>
                        </div>
                        <div className="form-group col-12 goBackLogin text-right">
                            <span className="a hover" onClick={this.goLogin}><i className="fa fa-arrow-left"></i> Go back to login</span>
                        </div>
                        <br />
                        {this.state.validUserFound === false &&
                            <div>
                                <span style={{ color: 'red' }}><h5>Invalid User Credentials</h5></span>
                            </div>
                        }
                    </div>
                </form>
            </div>
        );
    }
}
export default withRouter(TFALogin);