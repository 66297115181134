import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import './login.css';
import logo from '../../img/logo_tiny.png';
import { resetPassword, validateResetToken } from '../../common/services/AuthService';

import { toast } from '@rickylandino/react-messages';

function ResetPassword(props) {
    const { register, handleSubmit, setValue, getValues, watch, setError, clearErrors,
        formState: { errors }, control } = useForm();

    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        if (props.location.search) {
            validateResetToken(props.location.search.substring(1)).then(data => {
                if (data) {
                    setFullName(data.fullName);
                    setUsername(data.username);
                }
            });
        } else if (props.location.state.username) {
            setFullName(props.location.state.fullName);
            setUsername(props.location.state.username);
        }
        
    }, []);

    function isPasswordEqual(e) {
        setValue("passwordConfirm", e.target.value)

        if (e.target.value && getValues().password !== e.target.value) {
            setError("passwordConfirm", {
                type: "manual",
                message: "Passwords do not match",
            });
        } else {
            clearErrors("passwordConfirm");
        }
    }

    function submitPassword(e) {
        e.preventDefault();

        let userInfo = {
            username: username,
            newPassword: getValues().passwordConfirm
        };

        resetPassword(userInfo).then(data => {
            if (data) {
                toast.success("Password has been updated");

                directToLogin();
            }
        });
    }

    function directToLogin() {
        let path = '/';
        props.history.push(path);
    }

    return (
        <div className="background">
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                    <img src={logo} height="30px" alt="ONSite WebApp" aria-roledescription="logo" />
                    <span className="page-logo-text mr-1">ONsite Mammography</span>
                </div>
                <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                    {fullName ?
                        <>
                    <h2>Welcome, {fullName}</h2>
                    <p>Please update your password below</p>
                    <form>
                        <div className="form-group col-12">
                            <label className="form-label">Password</label>
                            <input {...register("password", { required: true })} className="form-control-custom" type="password"/>
                        </div>
                        <div className="form-group col-12">
                            <label className="form-label">Password Confirm</label>
                           {/* <input {...register("passwordConfirm", { required: true })} className="form-control-custom" type="password" onChange={isPasswordEqual} />*/}
                            <Controller
                                name="passwordConfirm"
                                control={control}
                                render={({ field }) => {
                                    // sending integer instead of string.
                                    return <input {...field} onChange={isPasswordEqual} className="form-control-custom" type="password" />;
                                }}
                            />    
                            {errors.passwordConfirm && <pre className="text-danger mb-0 ml-3">{errors.passwordConfirm.message}</pre>}
                        </div>

                        <button className="btn btn-default float-right mt-5" onClick={submitPassword}>Reset Password</button>
                            </form>
                            </>
                        :
                        <h2>This link is no longer valid</h2>
                    }

                    <p className="a hover text-icon" onClick={directToLogin}><i className="fa fa-arrow-left" /> Go To Login</p>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ResetPassword);