import React, { Component } from 'react';
import TopNav from './Navigation/TopNav';
import TopModal from './Navigation/TopModal';
import $ from 'jquery';
import { hashwordConversion } from '../common/services/ApiService';

export class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLeftNav: false
        }

        this.showLeftNav = this.showLeftNav.bind(this);
    }

    showLeftNav() {
        this.setState({
            showLeftNav: !this.state.showLeftNav
        }, () => {
            if (this.state.showLeftNav) {
                $(".page-content-overlay").addClass("yes");
            }
            else {
                $(".page-content-overlay").removeClass("yes");
            }
        });
    }

    

  render () {
    return (
        <div className="page-wrapper">
            <div className="page-inner">
                <div className="page-content-wrapper">
                    {/* BEGIN Page Header */}
                    <TopNav showLeftNav={this.showLeftNav} {...this.props} />
                    {/*<div className="m-4">*/}
                    {/*    <button onClick={() => hashwordConversion()}>Encrypt Password</button>*/}
                    {/*</div>*/}
                    {/* END Page Header */}
                    {/* BEGIN Page Content */}
                    {/* the #js-page-content id is needed for some plugins to initialize */}
                    <main id="js-page-content" role="main" className="page-content">
                        
                        {this.props.children}
                    </main>
                    {/* this overlay is activated only when mobile menu is triggered */}
                    <div onClick={this.showLeftNav} className="page-content-overlay" data-action="toggle" data-class="mobile-nav-on" /> {/* END Page Content */}
                    {/* BEGIN Page Footer */}
                    
                    {/* END Page Footer */}
                    {/* BEGIN Shortcuts */}
                    <TopModal />
                </div>
            </div>
        </div>
    );
  }
}
