import React, { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';

import { Modal } from 'react-bootstrap';

import { toast } from '@rickylandino/react-messages';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { allFacilities } from '../../common/services/ReportService';
import { insertPrimaryCarePhysician } from '../../common/services/MaintenanceService';

import NumberFormat from 'react-number-format';

export default function PCPModal(props) {
    const { register, handleSubmit, setValue, getValues,
        formState: { errors }, control } = useForm();

    const [facilityList, setFacilityList] = useState([]);

    var alive = false;

    const [modalInfo, setModalInfo] = useState({
        showModal: false,
        pcpItem: {
            facilityID: '',
            fName: '',
            lName: '',
            fax: '',
            inactiveYN: false
        }
    });

    useEffect(() => {
        alive = true;

        setModalInfo({
            ...modalInfo,
            showModal: true
        });

        combineLatest(
            allFacilities
        ).pipe(takeWhile(() => alive)).subscribe(([facilities]) => {
            setFacilityList(facilities);
            if (props.from === 'manageFacility') {
                setValue('facilityID', props.facilityID);
            }
            
        });

        return () => { alive = false };
    }, []);

    function closeModal() {

        setModalInfo({
            showModal: false,
            pcpItem: {
                facilityID: '',
                fName: '',
                lName: '',
                fax: '',
                inactiveYN: false
            }
        });
    }

    function handleUpdate() {
        var item = modalInfo.pcpItem;

        item.inactiveYN = getValues().inactiveYN ? 1 : 0;
        item.fName = getValues().fName;
        item.lName = getValues().lName;
        item.fax = getValues().fax;
        item.facilityID = getValues().facilityID;

        if (props.from === 'manageFacility') {
            if (props.facilityID) {
                insertPrimaryCarePhysician(item).then(data => {
                    toast.success("Primary Care Physician Added");

                    if (props.from === 'manageFacility') {
                        item.primaryCarePhysicianID = data;
                        setTimeout(() => {
                            props.hideModal(item);
                        }, 1000);
                    }

                    closeModal();
                });
            } else {
                item.primaryCarePhysicianID = '00000000-0000-0000-0000-000000000000';
                item.facilityID = '00000000-0000-0000-0000-000000000000';
                setTimeout(() => {
                    props.hideModal(item);
                }, 1000);
            }
        }
        
        
    }

    return (
        <Modal size='lg' show={modalInfo.showModal} backdrop='static' onHide={closeModal}>
            <Modal.Header>
                <h1>Primary Care Physician</h1>
            </Modal.Header>
            <Modal.Body>
                <div className="panel-content">
                    <div className="row">
                        <div className="form-group col-lg-6 col-12">
                            <label className="form-label">First Name</label>
                            <input className="form-control-custom" {...register("fName")} />
                        </div>

                        <div className="form-group col-lg-6 col-12">
                            <label className="form-label">Last Name</label>
                            <input className="form-control-custom" {...register("lName")} />
                        </div>

                        <div className="form-group col-lg-6 col-12">
                            <label className="form-label">Fax</label>
                            <Controller
                                name="fax"
                                control={control}
                                defaultValue={getValues().fax}
                                render={({ field }) => <NumberFormat {...field} className="form-control-custom" format="(###) ###-####" mask="_" onValueChange={(val) => setValue("fax", val.formattedValue)} />}
                            />
                        </div>

                        {props.facilityID &&
                            <div className="form-group col-lg-6 col-12">
                                <label className="form-label">Facility</label>
                                <select className="form-control-custom" {...register("facilityID")}>
                                    <option></option>
                                    {facilityList.map((f, idx) => <option key={f.facilityID} value={f.facilityID}>{f.facilityName}</option>)}
                                </select>
                            </div>
                        }

                        <div className="form-group col-12">
                            <label className="form-label">Set Inactive</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("inactiveYN")} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-submit" onClick={handleUpdate}>Save</button>
                        <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={closeModal}>Cancel</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}