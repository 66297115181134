import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ManageComponents from './ManageComponents';
import { getReportDefinitionPieces } from '../../../common/services/ReportService';
import { SmartMessageBox } from "../../../common/utils/functions";

import Card from 'react-bootstrap/Card';
import '../../../css/card.css';
import OrderComponents from '../Definitions/OrderComponents';

import $ from 'jquery';


class DefinitionHome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDefinition: true,
            showManageTemplates: false,
            showManageComponents: false
        }
    }

    componentDidMount() {
        getReportDefinitionPieces();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    setPageDirty = () => {
        this.isDirty = true;
    }

    setPageClean = () => {
        this.isDirty = false;
    }

    toggleTags = (e) => {
        let target = e.target;

        $('.nav-tabs').on('show.bs.tab', 'a', function (event) {
            if (this.isDirty) {
                event.preventDefault();
            }
        });

        if (this.isDirty) {
            SmartMessageBox(
                {
                    title: "You are about to lose your changes.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: "[No][Yes]"
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        this.isDirty = false;

                        switch (target.id) {
                            case 'template':
                                window.$('[href="#tab-template"]').tab('show');
                                this.setState({
                                    showDefinition: false,
                                    showManageTemplates: true,
                                    showManageComponents: false
                                });
                                
                                break;
                            case 'component':
                                window.$('[href="#tab-component"]').tab('show');
                                this.setState({
                                    showDefinition: false,
                                    showManageTemplates: false,
                                    showManageComponents: true
                                });
                                
                                break;
                            case 'definition':
                                window.$('[href="#tab-definition"]').tab('show');
                                this.setState({
                                    showDefinition: true,
                                    showManageTemplates: false,
                                    showManageComponents: false
                                });

                                break;
                            default:
                                break;
                        }

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        } else {
            switch (e.target.id) {
                case 'template':
                    this.setState({
                        showDefinition: false,
                        showManageTemplates: true,
                        showManageComponents: false
                    });
                    break;
                case 'component':
                    this.setState({
                        showDefinition: false,
                        showManageTemplates: false,
                        showManageComponents: true
                    });
                    break;
                case 'definition':
                    this.setState({
                        showDefinition: true,
                        showManageTemplates: false,
                        showManageComponents: false
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col col-12 mx-auto pt-5 mt-3">

                    <ul className="margin-top-20 nav nav-tabs nav-email" role="tablist">
                        <li className="nav-item">
                            <a className='active nav-link fs-lg px-4' data-toggle="tab" href="#tab-definition" role="tab" onMouseDown={this.toggleTags} id="definition">
                                <i className="fal fa-th-large fa-1x text-primary" id="definitionBtn" onClick={this.toggleTags} id="definition"/>
                                <span className="hidden-sm-down ml-1" onClick={this.toggleTags} id="definition">Report Definitions</span>
                            </a>
                        </li>
                        
                        
                        <li className="nav-item">
                            <a className="nav-link fs-lg px-4" data-toggle="tab" href="#tab-component" role="tab" onMouseDown={this.toggleTags} id="component">
                                <i className="fal fa-puzzle-piece fa-1x text-primary" onClick={this.toggleTags} id="component"></i>
                                <span className="hidden-sm-down ml-1" onClick={this.toggleTags} id="component">Report Components</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content p-3">
                        <div className="tab-pane fade show active" id="tab-definition" role="tabpanel" aria-labelledby="tab-definition">
                            {this.state.showDefinition &&
                                <Card className="pinkCard">
                                    <Card.Header>
                                        <span>Report Definitions</span>
                                    </Card.Header>
                                    <Card.Body>
                                    <OrderComponents />
                                    </Card.Body>
                                </Card>
                            }
                        </div>

                        

                        <div className="tab-pane fade" id="tab-component" role="tabpanel" aria-labelledby="tab-component">
                            {this.state.showManageComponents &&
                                <Card className="pinkCard">
                                    <Card.Header>
                                        <span>Component Builder</span>
                                    </Card.Header>
                                    <Card.Body>
                                    <ManageComponents setPageDirty={this.setPageDirty} setPageClean={this.setPageClean} />
                                    </Card.Body>
                                </Card>
                            }
                        </div>
                    </div>



                </div>
            </div>
        );
    }
}

export default withRouter(DefinitionHome);