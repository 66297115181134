import React, { useState, useEffect } from 'react';
import { Route, withRouter, Redirect, Switch } from 'react-router';
import { Layout } from './components/Layout';
import Login from './components/Auth/Login';
import Providers from './components/Maintenance/Providers';
import Globals from '../src/config/globals';
import PatientsHome from './components/Patient/PatientsHome';
import PatientDashboard from './components/Patient/PatientDashboard';

import Modal from 'react-modal';
import ExamSearch from './components/Exams/ExamSearch';
import RecallHome from './components/Recall/RecallHome';
import AdminHome from './components/Admin/AdminHome';
import WorklistHome from './components/Worklist/WorklistHome';

import Templates from './components/Report/Templates/ReportTemplates';
import Components from './components/Report/Components/ReportComponents';
import Definitions from './components/Report/Definitions/ReportDefinitions';
import Library from './components/Report/Library/ReportLibrary';
import DefinitionHome from './components/Report/Definition/DefinitionHome';
import { userAssociatedFacilities, getAllTypes, getVersion } from './common/services/ApiService';
import { insertNewVersion } from './common/services/CachingService';
import DailyExamDashboard from './components/DailyExams/DailyExamDashboard';
import { activeRoute } from './common/services/SpaService';
import ResetPassword from './components/Auth/ResetPassword';
import DetectActivity from './components/Helpers/DetectActivity';
import Insurance from './components/Maintenance/Insurance';

import chalk from 'chalk';
import { Modal as AntModal } from 'antd';
import WhatsNew from './components/WhatsNew/WhatsNew';

import { toast } from '@rickylandino/react-messages';
import ViewAuditHistory from './components/Admin/View/ViewAuditHistory';
import { getLoadInfo } from './common/services/UsersService';

function App(props) {

    const [state, setState] = useState({
        selectedFacility: {},
        theme: window.sessionStorage.getItem("theme") || "light",
        version: '',
        refreshCache: false
    });


    const [refreshCache, setRefreshCache] = useState(false);

    useEffect(() => {
        if (window.sessionStorage.getItem("userInfo")) {
            Globals.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
            Globals.tokenStuff = JSON.parse(window.sessionStorage.getItem("tokenStuff"));
            Globals.bearer = Globals.tokenStuff.accessToken;
            getLoadInfo(Globals.userInfo.userId).then(data => {
                userAssociatedFacilities.next(data.userAssociatedFacilities);
                Globals.associatedFacilities = data.userAssociatedFacilities;
            })
            getAllTypes();
        }

        if (window.sessionStorage.getItem("defaultFacility")) {
            Globals.defaultFacility = JSON.parse(window.sessionStorage.getItem("defaultFacility"));
        }

        Modal.setAppElement('#root');

        getVersionInformation();

        AntModal.destroyAll();
    }, []);

    useEffect(() => {
        activeRoute.next(props.history.location.pathname);
    }, [props.history.location]);

    async function emptyCache(appVersion) {
        window.localStorage.setItem("PINK_APP_VERSION", appVersion);

        if ('caches' in window) {
            // Service worker cache should be cleared with caches.delete()
            const cacheKeys = await window.caches.keys();
            await Promise.all(cacheKeys.map(key => {
                window.caches.delete(key)
            }));
        }

        window.location.replace(window.location.href);
    }

    async function getVersionInformation () {
        let basePath = window.location.href;
        var baseUrl = basePath.replace(/\/+$/, '') + '/' + 'meta.json';

        const response = await fetch(baseUrl, {
            cache: 'no-store'
        });

        const meta = await response.json();
        if (!window.sessionStorage.getItem("cacheRefreshed")) {
            getVersion().then(data => {
                console.log(data);
                if (`${meta.version}` !== data.cacheVersion) {
                    toast.loading("Update in progress");

                    setRefreshCache(true);

                    insertNewVersion(meta.version).then(data => {
                        window.sessionStorage.setItem("cacheRefreshed", true);
                        emptyCache(meta.version);
                    });
                } else if ((window?.localStorage?.PINK_APP_VERSION && (window?.localStorage?.PINK_APP_VERSION !== data.cacheVersion)) || !window?.localStorage?.PINK_APP_VERSION) {
                    toast.loading("Update in progress");
                    setRefreshCache(true);

                    window.sessionStorage.setItem("cacheRefreshed", true);
                    emptyCache(data.cacheVersion);
                }

                let versionToUse = data.webVersion?.split('+')[0];

                setState({
                    ...state,
                    version: versionToUse
                });
            });
        } else {
            console.log(chalk.green("Cache Refreshed"));
        }

        console.log(chalk.blueBright("Version: " + window.localStorage?.PINK_APP_VERSION));
    }

    function changeFacility() {
        setState({
            ...state,
            selectedFacility: Globals.selectedFacility
        });
    }

    function updatePatientsLoading() {
        setState({
            ...state,
            loadingPatients: Globals.loadingPatients
        });
    }

    function updatePatients() {
        setState({
            ...state,
            patientsLoaded: true,
            loadingPatients: false
        });
        Globals.loadingPatients = false;
    }

    function toggleDarkMode(theme) {

        setState({
            ...state,
            theme
        });

        Globals.theme = theme;
    }

    const handleRedirectHome = () => {
        return (<Redirect to='/' />)
    }

    return (
        <div className="nav-function-top" data-theme={state.theme}>
            {
                (props.location.pathname === '/' || props.location.pathname === '/reset-password') ? (
                    <div>
                        <Route exact path='/' render={(props) => <Login toggleDarkMode={toggleDarkMode} />} />
                        <Route exact path='/reset-password' component={ResetPassword} />
                    </div>
                ) : window.sessionStorage.getItem("userInfo") ? (

                        <DetectActivity>
                            <Layout changeFacility={changeFacility} updatePatientsLoading={updatePatientsLoading} updatePatients={updatePatients} toggleDarkMode={toggleDarkMode} handleRedirectHome={handleRedirectHome}>
                            <Switch>
                                <Route path='/patients' render={(props) => <PatientsHome selectedFacility={state.selectedFacility} loadingPatients={state.loadingPatients} patientsLoaded={state.patientsLoaded} />} />
                                <Route exact path='/patient-dashboard' render={(props) => (props.location.state ? <PatientDashboard updatePatients={updatePatients} /> : <Redirect to='/patients' />)} />
                                <Route exact path='/admin-panel' render={(props) => (JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin ? <AdminHome /> : <Redirect to='/patients' />)} />
                                <Route exact path='/report-definitions' render={(props) => (JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin ? <DefinitionHome /> : <Redirect to='/patients' />)} />
                                <Route exact path='/providers' render={(props) => (JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin ? <Providers /> : <Redirect to='/patients' />)} />
                                    <Route exact path='/audit-history' render={(props) => ((JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin || JSON.parse(window.sessionStorage.getItem("userInfo")).managerYN) ? <ViewAuditHistory /> : <Redirect to='/patients' />)} />
                            </Switch>

                            <Route path='/recall' component={RecallHome} />
                            <Route path='/insurance-maintenance' component={Insurance} />
                            <Route path='/exam-search' component={ExamSearch} />
                            <Route path='/worklist' render={(props) => <WorklistHome selectedFacility={state.selectedFacility} />} />
                            <Route path='/reporttemplates' component={Templates} />
                            <Route path='/reportcomponents' component={Components} />
                            <Route path='/reportdefinitions' component={Definitions} />
                            <Route path='/reportlibrary' component={Library} />
                            <Route path='/daily-exams' component={DailyExamDashboard} />
                            <Route path="/what's-new" component={WhatsNew} />

                                
                                
                                
                            </Layout>
                        </DetectActivity>)
                        :
                        <Redirect to='/' />
            }

            {(props.location.pathname === '/' || props.location.pathname === '/reset-password') ? 
                <div style={{ position: 'fixed', bottom: '0', left: '10px' }}><span className="hidden-md-down fw-500"><i className="far fa-copyright"></i> {new Date().getFullYear()} <span className="fw-700">ONsite Women's Health.</span> All Rights Reserved</span> &nbsp; v{state.version}</div>
                :
                <footer className="page-footer" role="contentinfo">
                    <div className="d-flex align-items-center flex-1 text-muted">

                        <span className="hidden-md-down fw-500"><i className="far fa-copyright"></i> {new Date().getFullYear()} <span className="fw-700">ONsite Women's Health.</span> All Rights Reserved</span> &nbsp; v{state.version}
                    </div>
                </footer>
            }

            {refreshCache && <div className="refresh-overlay" />}
        </div>
    );
}

export default withRouter(App);