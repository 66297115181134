import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import Globals from '../../../config/globals';

import { Editor } from '@tinymce/tinymce-react';

import { SmartMessageBox } from "../../../common/utils/functions";
import { reportComponents, getAllComponents, insertComponent, updateComponent, deleteComponent, reportComponentTypes } from '../../../common/services/ReportService';

import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { userAssociatedFacilities } from '../../../common/services/ApiService';

import { toast } from '@rickylandino/react-messages';

import { Select } from 'antd';

class ManageComponents extends Component {

    constructor(props) {

        super(props);
        this.alive = false;

        this.state = {
            editorContent: '',
            formFields: {
                componentName: '',
                componentID: '',
                selectedComponentID: ''
            },
            componentList: [],
            componentTypesList: [],
            addComponent: false,
            newComponentName: '',
            selectedComponentType: {},
            associatedFacilities: []
        }
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            reportComponents,
            reportComponentTypes
        ).pipe(takeWhile(() => this.alive)).subscribe(([rc, rct]) => {
            if (rc && rc.length > 0 && rct && rct.length > 0) {
                this.setState({
                    componentList: !this.state.selectedComponentType.reportComponentTypesID ? rc.filter(c => c.reportComponentTypesID === rct[0].reportComponentTypesID) : rc.filter(c => c.reportComponentTypesID === this.state.selectedComponentType.reportComponentTypesID),
                    fullComponentList: rc,
                    componentTypesList: rct,
                    selectedComponentType: !this.state.selectedComponentType.reportComponentTypesID ? rct[0] : this.state.selectedComponentType
                });
            }
        });

        userAssociatedFacilities.pipe(takeWhile(() => this.alive)).subscribe((data) => {
            if (data?.length > 0) {
                
                this.setState({
                    associatedFacilities: data
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'associateFacility') {
            let formFields = {...this.state.formFields};
            formFields.facilityID = value ? formFields.facilityID : null;
            formFields.associateFacility = value;

            if (!value) {
                formFields.selectedComponentID = Math.random();
            }

            this.setState({ formFields }, () => {
                this.isDirty = true;
            });

        } else if (name === 'facilityID') {
            let formFields = { ...this.state.formFields };

            formFields.facilityID = value;
            formFields.selectedComponentID = Math.random();

            this.setState({ formFields, editorContent: this.state.editorContent }, () => {
                this.isDirty = true;
            });
        } else {
            this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
                this.isDirty = true;
                Globals.isDirtyPage = true;
            });
        }
    }

    handleFacilityChange = (selectedFacilityID) => {

        let formFields = { ...this.state.formFields };

        formFields.facilityID = selectedFacilityID;
        formFields.selectedComponentID = Math.random();

        this.setState({ formFields, editorContent: this.state.editorContent }, () => {
            this.isDirty = true;
        });
    }

    handleComponentTypeChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let selectedComponentType = this.state.componentTypesList.find(ct => ct.reportComponentTypesID === value);

        let componentList = this.state.fullComponentList.filter(c => c.reportComponentTypesID === value);

        let formFields = { ...this.state.formFields, [name]: value };
        formFields.selectedComponentID = '';
        formFields.componentName = '';

        this.setState({
            selectedComponentType,
            componentList,
            editorContent: '',
            formFields
        }, () => {
            this.isDirty = true;
            Globals.isDirtyPage = true;
        })
    }

    handleEditorChange = (content, editor) => {
        this.setState({
            editorContent: content
        }, () => {
            this.isDirty = true;
            Globals.isDirtyPage = true;
            this.props.setPageDirty();
        });
    }

    handleTemplateSelection = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let formFields = {};
        let editorContent = '';

        let showDelete = this.state.showDelete;

        if (value === '') {
            formFields = {
                componentName: '',
                selectedComponentID: ''
            };

            showDelete = false;
        } else {
            formFields = this.state.componentList.find(t => t.componentID === value);
            formFields.selectedComponentID = value;
            formFields.associateFacility = formFields.facilityID ? true : false;
            editorContent = formFields.componentHtml;

            showDelete = true;
        }

        this.setState({ formFields, editorContent, showDelete });
    }

    handleSubmit = () => {

        if (!this.state.formFields.componentName ||
            !this.state.formFields.reportComponentTypesID ||
            !this.state.editorContent) {

            toast.warning("Please fill out required information.")

        } else {
            let selectedComponentType = this.state.componentTypesList.find(ct => ct.reportComponentTypesID === this.state.formFields.reportComponentTypesID);

            if (this.state.formFields.componentID) {
                let component = this.state.formFields;

                component.componentHtml = this.state.editorContent

                updateComponent(component).then(data => {
                    if (data) {
                        toast.success("Component has been updated.")
                    } else {
                        toast.error("Something went wrong.")
                    }



                    this.isDirty = false;
                    Globals.isDirtyPage = false;
                    this.props.setPageClean();

                    this.setState({
                        selectedComponentType
                    }, () => {
                        getAllComponents();
                    });

                }).catch(error => {
                    console.log(error);
                });
            } else {
                let component = {
                    componentName: this.state.formFields.componentName,
                    componentHtml: this.state.editorContent,
                    reportComponentTypesID: this.state.formFields.reportComponentTypesID,
                    facilityID: this.state.formFields.facilityID
                }

                insertComponent(component).then(data => {
                    if (data) {

                        toast.success("Component has been added.")
                        this.setState({
                            formFields: { ...this.state.formFields, selectedComponentID: data, selectedComponentType },
                            showDelete: true
                        }, () => getAllComponents());

                        this.cancelAddComponent();
                    } else {
                        toast.error("Something went wrong.")
                    }

                    this.isDirty = false;
                    Globals.isDirtyPage = false;
                    this.props.setPageClean();

                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }

    deleteComponent = () => {

        SmartMessageBox(
            {
                title: "You are about to delete this component.",
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {

                    let component = this.state.formFields;
                    component.componentHtml = this.state.editorContent;

                    deleteComponent(component).then(data => {
                        if (data) {

                            toast.success("Component has been deleted.")

                            this.isDirty = false;
                            Globals.isDirtyPage = false;
                            this.props.setPageClean();

                            let formFields = {
                                componentName: '',
                                componentID: '',
                                selectedComponentID: ''
                            };

                            this.setState({
                                formFields,
                                editorContent: '',
                                uploadedImages: [],
                                showDelete: false,
                            });

                            getAllComponents();

                        } else {
                            toast.error("Something went wrong.")
                        }
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    window.history.pushState(null, null, null);
                    return 0;
                }
            }
        );

    }

    addComponent = () => {
        let formFields = {
            componentName: '',
            selectedComponentID: '',
            reportComponentTypesID: ''
        };

        this.setState({
            showDelete: false,
            addComponent: true,
            formFields
        });
    }

    cancelAddComponent = () => {
        this.setState({
            addComponent: false
        });
    }

    render() {
        const isFacilitySpecific = this.state.formFields.associateFacility;
        const facility = this.state.associatedFacilities?.find(f => f.facilityModel.facilityID == this.state.formFields.facilityID);

        let densityNotice = `<p>{{Dense Breast Notice}}</p>`;

        //if (facility?.facilityModel.densityDisplayType === 'Checkbox') {
        //    //densityNotice = `
        //    //    <p><sub><em>${facility.facilityModel.densityVerbiage}</em></sub></p>
        //    //    <p>
        //    //    <input id="denseBreast" name="denseBreast" type="checkbox" value="yes" /> You have dense breasts <br />
        //    //    <input id="denseBreast" name="denseBreast" type="checkbox" value="no" /> You do not have dense breasts
        //    //    </p>
        //    //`;

        //    densityNotice = `<p>{{Dense Breast Notice}}</p>`;
        //} else {
        //    densityNotice = `<p>{{Dense Breast Language}}</p>`;
        //}

        const { Option } = Select;

        return (
            <Fragment>
                <div className="row mt-3">
                    <div className="col-lg-3 col-12 mb-5">
                        <Fragment>
                            <label className="form-label w-100">Filter By Component Type</label>
                            <select className="form-control-custom" value={this.state.selectedComponentType.reportComponentTypesID} name="reportComponentTypesID" onChange={this.handleComponentTypeChange}>
                                {this.state.componentTypesList.map((item, idx) => <option key={idx} value={item.reportComponentTypesID}>{item.componentTypeName}</option>)}
                            </select>
                        </Fragment>
                    </div>

                    <div className="col-lg-9 col-12">
                        {(this.state.formFields.selectedComponentID !== '' || this.state.addComponent) &&
                            <Fragment>
                                {this.state.formFields.selectedComponentID ?
                                    <button className="float-right btn btn-submit margin-0-10" onClick={this.handleSubmit}>Update Component</button>
                                    :
                                    <button className="float-right btn btn-submit margin-0-10" onClick={this.handleSubmit}>Save Component</button>
                                }
                            </Fragment>
                        }

                        {!this.state.addComponent ?
                            <button type="button" className="btn btn-success margin-0-10 float-right" onClick={this.addComponent}>
                                <i className="far fa-plus-square" /> Add New Component
                            </button>
                            :
                            <span className="a hover float-right color-pink margin-0-10" onClick={this.cancelAddComponent}>Cancel New Component <i className="fa fa-times" /></span>
                        }
                    </div>
                    {!this.state.addComponent &&
                        <div className="col-lg-3 col-12 mb-5">
                            <label className="form-label">Component</label>
                            {this.state.componentList.length > 0 ?
                                <select className="form-control-custom" value={this.state.formFields.selectedComponentID} name="selectedComponentID" onChange={this.handleTemplateSelection}>
                                    <option value='' disabled>Select a Component</option>
                                    {this.state.componentList.map((item, idx) => <option key={idx} value={item.componentID}>{item.componentName}</option>)}
                                </select>
                                :
                                <p className="ml-4"><em>No components for this type.</em></p>
                            }
                        </div>
                    }
                </div>

                <div className="row">
                    <div className="horizontal-divider mt-3 mb-3"></div>
                </div>
                {(this.state.formFields.selectedComponentID !== '' || this.state.addComponent) &&
                    <Fragment>
                        <div className="row">
                            <div className="form-group col-lg-8 col-12">
                                <label className="form-label">Component Name <span className="color-pink">*</span></label>
                                <input type="text" name="componentName" value={this.state.formFields.componentName} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label className="form-label">Component Type <span className="color-pink">*</span></label>
                                <select className="form-control-custom" value={this.state.formFields.reportComponentTypesID} name="reportComponentTypesID" onChange={this.handleInputChange}>
                                    <option value='' disabled>Select a Component Type</option>
                                    {this.state.componentTypesList.map((item, idx) => <option key={idx} value={item.reportComponentTypesID}>{item.componentTypeName}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-12">
                                <label className="form-label">Facility Specific Component</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" id="" name="associateFacility" checked={this.state.formFields.associateFacility || false} onChange={this.handleInputChange} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                            {this.state.formFields.associateFacility &&
                                <div className="col-lg-3 col-12 mb-5">
                            <label className="form-label">Select a facility</label>
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={this.handleFacilityChange}
                                className="form-control-custom w-100"
                                bordered={false}
                                value={this.state.formFields.facilityID}
                                showSearch
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {this.state.associatedFacilities.filter(item => item.facilityModel.inactiveYN !== 1).map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                            </Select>

                                {/*    <select className="form-control-custom" value={this.state.formFields.facilityID || ''} name="facilityID" onChange={this.handleInputChange}>*/}
                                {/*        <option value='' disabled>Select a facility</option>*/}
                                {/*{this.state.associatedFacilities.map((item, idx) => <option key={idx} value={item.facilityModel.facilityID}>{item.facilityModel.facilityNumber && item.facilityModel.facilityNumber + " - "}{item.facilityModel.facilityName}</option>)}*/}
                                {/*    </select>*/}
                                </div>
                            }
                        </div>
                        <div className="row">
                            {this.state.showDelete &&
                                <div className="col-12 mb-4">
                                    <label className="form-label">Delete This Component</label>
                                    <div className="ml-3 text-danger fa-15x hover" onClick={this.deleteComponent}><i className="far fa-trash-alt"></i></div>
                                </div>
                            }

                            <div className="form-group col-12">
                                <label className="form-label">Component Content <span className="color-pink">*</span></label>
                                <Editor
                                    onEditorChange={this.handleEditorChange}
                                    initialValue={this.state.editorContent} //This tricks the component to rerendering with correct content as template selection changes
                                    key={this.state.formFields.selectedComponentID}
                                    init={{
                                        height: 500,
                                        selector: 'textarea',
                                        image_title: true,
                                        //images_upload_handler: example_image_upload_handler,
                                        image_uploadtab: true,
                                        plugins: 'advlist autolink lists link charmap print preview hr anchor pagebreak code table',
                                        branding: false,
                                        content_style: "body { line-height: 4pt; }",
                                        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | mergeFieldsBtn densityNoticeBtn companyLogoBtn | code table',
                                        lineheight_formats: "1pt 2pt 3pt 4pt 5pt 6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt",
                                        setup: function (editor, ) {
                                            editor.ui.registry.addButton('companyLogoBtn', {
                                                text: 'Company Logo',
                                                onAction: function (_) {
                                                    editor.insertContent('<p style="text-align: center;"><strong><img style="max-height: 75px;" id="logo" src="{{facility.logo}}" /></strong></p>');
                                                }
                                            });

                                            editor.ui.registry.addButton('densityNoticeBtn', {
                                                text: 'Density Notice',
                                                onAction: function (_) {
                                                    editor.insertContent(densityNotice);
                                                }
                                            });

                                            editor.ui.registry.addMenuButton('mergeFieldsBtn', {
                                                text: 'Merge Fields',
                                                fetch: function (callback) {
                                                    var items = [
                                                        {
                                                            type: 'nestedmenuitem',
                                                            text: 'Patient Fields',
                                                            getSubmenuItems: function () {
                                                                return [
                                                                    { type: 'menuitem', text: 'First Name', onAction: function () { editor.insertContent('{{patient.firstname}}'); } },
                                                                    { type: 'menuitem', text: 'Last Name', onAction: function () { editor.insertContent('{{patient.lastname}}'); } },
                                                                    { type: 'menuitem', text: 'Middle Name', onAction: function () { editor.insertContent('{{patient.mName}}'); } },
                                                                    { type: 'menuitem', text: 'Recall Date', onAction: function () { editor.insertContent('{{patient.recalldate}}'); } },
                                                                    { type: 'menuitem', text: 'DOB', onAction: function () { editor.insertContent('{{patient.dob}}'); } },
                                                                    { type: 'menuitem', text: 'Age', onAction: function () { editor.insertContent('{{patient.age}}'); } },
                                                                    { type: 'menuitem', text: 'Next Appointment Date', onAction: function () { editor.insertContent('{{patient.nextApptDate}}'); } },
                                                                    { type: 'menuitem', text: 'Address 1', onAction: function () { editor.insertContent('{{patient.address1}}'); } },
                                                                    { type: 'menuitem', text: 'Address 2', onAction: function () { editor.insertContent('{{patient.address2}}'); } },
                                                                    { type: 'menuitem', text: 'City', onAction: function () { editor.insertContent('{{patient.city}}'); } },
                                                                    { type: 'menuitem', text: 'State', onAction: function () { editor.insertContent('{{patient.state}}'); } },
                                                                    { type: 'menuitem', text: 'Zip', onAction: function () { editor.insertContent('{{patient.zip}}'); } },
                                                                    { type: 'menuitem', text: 'Home Phone', onAction: function () { editor.insertContent('{{patient.homePhone}}'); } },
                                                                    { type: 'menuitem', text: 'Work Phone', onAction: function () { editor.insertContent('{{patient.workPhone}}'); } },
                                                                    { type: 'menuitem', text: 'Work Phone Ext.', onAction: function () { editor.insertContent('{{patient.workPhoneExt}}'); } },
                                                                    { type: 'menuitem', text: 'Cell Phone', onAction: function () { editor.insertContent('{{patient.cellPhone}}'); } },
                                                                    { type: 'menuitem', text: 'Email', onAction: function () { editor.insertContent('{{patient.email}}'); } },
                                                                    { type: 'menuitem', text: 'Notes', onAction: function () { editor.insertContent('{{patient.notes}}'); } },
                                                                    { type: 'menuitem', text: 'MRN', onAction: function () { editor.insertContent('{{patient.mrn}}'); } },
                                                                    { type: 'menuitem', text: 'Patient Dense', onAction: function () { editor.insertContent('{{patient.patientDense}}'); } },
                                                                    { type: 'menuitem', text: 'High Risk', onAction: function () { editor.insertContent('{{patient.highRisk}}'); } },
                                                                    { type: 'menuitem', text: 'Non Compliant', onAction: function () { editor.insertContent('{{patient.nonCompliant}}'); } },
                                                                    { type: 'menuitem', text: 'Custom Banner Alert', onAction: function () { editor.insertContent('{{patient.customBannerAlert}}'); } },
                                                                    { type: 'menuitem', text: 'Provider', onAction: function () { editor.insertContent('{{patient.providerID}}'); } },
                                                                    { type: 'menuitem', text: 'Insurance Company', onAction: function () { editor.insertContent('{{patient.insuranceID}}'); } },
                                                                    { type: 'menuitem', text: 'Primary Care Physician', onAction: function () { editor.insertContent('{{patient.primaryCarePhysicianID}}'); } },
                                                                ];
                                                            }
                                                        },
                                                        {
                                                            type: 'nestedmenuitem',
                                                            text: 'Patient Exam Fields',
                                                            getSubmenuItems: function () {
                                                                return [
                                                                    { type: 'menuitem', text: 'Exam Type', onAction: function () { editor.insertContent('{{patientExam.examType}}'); } },
                                                                    { type: 'menuitem', text: 'Exam Date', onAction: function () { editor.insertContent('{{patientExam.examDate}}'); } },
                                                                    { type: 'menuitem', text: 'Reason for Exam', onAction: function () { editor.insertContent('{{patientExam.reasonForExam}}'); } },
                                                                    { type: 'menuitem', text: 'Performing Technician', onAction: function () { editor.insertContent('{{patientExam.performingTech}}'); } },
                                                                    { type: 'menuitem', text: 'Referring MD', onAction: function () { editor.insertContent('{{patientExam.providerID}}'); } },
                                                                    { type: 'menuitem', text: 'Exam Complete Status Y/N', onAction: function () { editor.insertContent('{{patientExam.isExamCompleteYN}}'); } },
                                                                ];
                                                            }
                                                        },
                                                        {
                                                            type: 'nestedmenuitem',
                                                            text: 'Patient Result Fields',
                                                            getSubmenuItems: function () {
                                                                return [
                                                                    { type: 'menuitem', text: 'Density', onAction: function () { editor.insertContent('{{patientResult.density}}'); } },
                                                                    { type: 'menuitem', text: 'Recommendation', onAction: function () { editor.insertContent('{{patientResult.recommendation}}'); } },
                                                                    { type: 'menuitem', text: 'Recall Type', onAction: function () { editor.insertContent('{{patientResult.recallType}}'); } },
                                                                    { type: 'menuitem', text: 'Recall Date', onAction: function () { editor.insertContent('{{patientResult.recallDate}}'); } },
                                                                    { type: 'menuitem', text: 'Radiologist', onAction: function () { editor.insertContent('{{patientResult.radiologist}}'); } },
                                                                    { type: 'menuitem', text: 'Birad', onAction: function () { editor.insertContent('{{patientResult.birad}}'); } },
                                                                    { type: 'menuitem', text: 'Notes', onAction: function () { editor.insertContent('{{patientResult.notes}}'); } },
                                                                    { type: 'menuitem', text: 'Remarks', onAction: function () { editor.insertContent('{{patientResult.remarks}}'); } },
                                                                    { type: 'menuitem', text: 'Lost To Follow Up', onAction: function () { editor.insertContent('{{patientResult.losttoFollowUp}}'); } },
                                                                    { type: 'menuitem', text: 'Closed For Dx Exam', onAction: function () { editor.insertContent('{{patientResult.closedforDxExam}}'); } },
                                                                    { type: 'menuitem', text: 'Finding', onAction: function () { editor.insertContent('{{patientResult.finding}}'); } },
                                                                    { type: 'menuitem', text: 'Risk Grade', onAction: function () { editor.insertContent('{{patientResult.riskGrade}}'); } },
                                                                    { type: 'menuitem', text: 'Biopsy Type', onAction: function () { editor.insertContent('{{patientResult.biopsyType}}'); } },
                                                                    { type: 'menuitem', text: 'ER Receptor', onAction: function () { editor.insertContent('{{patientResult.erReceptor}}'); } },
                                                                    { type: 'menuitem', text: 'PR Receptor', onAction: function () { editor.insertContent('{{patientResult.prReceptor}}'); } },
                                                                    { type: 'menuitem', text: 'HER2/NEU Receptor', onAction: function () { editor.insertContent('{{patientResult.her2NeuReceptor}}'); } },
                                                                    { type: 'menuitem', text: 'FISH Receptor', onAction: function () { editor.insertContent('{{patientResult.fishReceptor}}'); } },
                                                                    { type: 'menuitem', text: 'Size Of Finding', onAction: function () { editor.insertContent('{{patientResult.sizeofFinding}}'); } },
                                                                    { type: 'menuitem', text: 'Axillary Node Status', onAction: function () { editor.insertContent('{{patientResult.axillaryNodeStatus}}'); } },
                                                                    { type: 'menuitem', text: 'Number Of Axillary Nodes', onAction: function () { editor.insertContent('{{patientResult.numberofAxillaryNodes}}'); } },
                                                                    { type: 'menuitem', text: 'Stage', onAction: function () { editor.insertContent('{{patientResult.stage}}'); } },
                                                                    { type: 'menuitem', text: 'MRI Performed', onAction: function () { editor.insertContent('{{patientResult.mriPerformed}}'); } },
                                                                ];
                                                            }
                                                        },
                                                        {
                                                            type: 'nestedmenuitem',
                                                            text: 'Facility Fields',
                                                            getSubmenuItems: function () {
                                                                return [
                                                                    { type: 'menuitem', text: 'Facility Name', onAction: function () { editor.insertContent('{{facility.facilityname}}'); } },
                                                                    { type: 'menuitem', text: 'Legal Name', onAction: function () { editor.insertContent('{{facility.legalname}}'); } },
                                                                    { type: 'menuitem', text: 'DBA Name', onAction: function () { editor.insertContent('{{facility.dbaname}}'); } },
                                                                    { type: 'menuitem', text: 'Address 1', onAction: function () { editor.insertContent('{{facility.address1}}'); } },
                                                                    { type: 'menuitem', text: 'Address 2', onAction: function () { editor.insertContent('{{facility.address2}}'); } },
                                                                    { type: 'menuitem', text: 'City', onAction: function () { editor.insertContent('{{facility.city}}'); } },
                                                                    { type: 'menuitem', text: 'State', onAction: function () { editor.insertContent('{{facility.state}}'); } },
                                                                    { type: 'menuitem', text: 'Zip', onAction: function () { editor.insertContent('{{facility.zip}}'); } },
                                                                    { type: 'menuitem', text: 'Phone', onAction: function () { editor.insertContent('{{facility.phone}}'); } },
                                                                    { type: 'menuitem', text: 'Fax', onAction: function () { editor.insertContent('{{facility.fax}}'); } },
                                                                    { type: 'menuitem', text: 'Email', onAction: function () { editor.insertContent('{{facility.email}}'); } }
                                                                ];
                                                            }
                                                        },
                                                        {
                                                            type: 'nestedmenuitem',
                                                            text: 'Patient Recall Fields',
                                                            getSubmenuItems: function () {
                                                                return [
                                                                    { type: 'menuitem', text: 'Primary Recall Type', onAction: function () { editor.insertContent('{{patientRecall.primaryRecallTypeID}}'); } },
                                                                    { type: 'menuitem', text: 'Primary Recall Date', onAction: function () { editor.insertContent('{{patientRecall.primaryRecallDate}}'); } },
                                                                    { type: 'menuitem', text: 'Primary Recall Sent', onAction: function () { editor.insertContent('{{patientRecall.primaryRecallSent}}'); } },
                                                                    { type: 'menuitem', text: 'Primary Recall Sent Date', onAction: function () { editor.insertContent('{{patientRecall.primaryRecallSentDate}}'); } },
                                                                    { type: 'menuitem', text: 'Secondary Recall Type', onAction: function () { editor.insertContent('{{patientRecall.secondaryRecallTypeID}}'); } },
                                                                    { type: 'menuitem', text: 'Seconday Recall Date', onAction: function () { editor.insertContent('{{patientRecall.secondaryRecallDate}}'); } },
                                                                    { type: 'menuitem', text: 'Secondary Recall Sent', onAction: function () { editor.insertContent('{{patientRecall.secondaryRecallSent}}'); } },
                                                                    { type: 'menuitem', text: 'Secondary Recall Sent Date', onAction: function () { editor.insertContent('{{patientRecall.secondaryRecallSentDate}}'); } }
                                                                ];
                                                            }
                                                        },
                                                        {
                                                            type: 'nestedmenuitem',
                                                            text: 'MWL',
                                                            getSubmenuItems: function () {
                                                                return [
                                                                    { type: 'menuitem', text: 'Appt Date', onAction: function () { editor.insertContent('{{mwl.apptDate}}'); } },
                                                                    { type: 'menuitem', text: 'Appt Time', onAction: function () { editor.insertContent('{{mwl.apptTime}}'); } }
                                                                ];
                                                            }
                                                        }
                                                    ];
                                                    callback(items);
                                                }
                                            });
                                        }
                                    }}
                                />
                            </div>

                            <div className="form-group col-12 padding-25-10 mt-4">
                                {this.state.formFields.selectedComponentID ?
                                    <button className="btn btn-submit" onClick={this.handleSubmit}>Update Component</button>
                                    :
                                    <button className="btn btn-submit" onClick={this.handleSubmit}>Save Component</button>
                                }

                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default withRouter(ManageComponents);