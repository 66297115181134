import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Globals from '../../config/globals';

import { Result, Spin, Button, Typography, message, Select } from 'antd';
import { InboxOutlined, LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleTwoTone } from '@ant-design/icons';

import { toast } from '@rickylandino/react-messages';


import { useDropzone } from 'react-dropzone';
import { importPatientExamsExcel, exportErrorList } from '../../common/services/ApiService';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userAssociatedFacilities } from '../../common/services/ApiService';
import Moment from 'moment';
import { Fragment } from 'react';

function MyDropzone(props) {
    const maxSize = 1048576;

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()
        let file = acceptedFiles[0];

        reader.onload = async function () {
            props.setFile(file);
            //props.addFile(file);

        }
        reader.readAsArrayBuffer(file);
    }, [])

    const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
        onDrop,
        minSize: 0,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xlsx, application/xls, .csv, application/vnd.ms-excel, text/csv'
    });

    const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

    return (
        <div className="ant-upload ant-upload-drag pt-4 mb-4">
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                {!isDragActive && 'Click here or drop an excel sheet here'}
                {isDragActive && !isDragReject && "Drop the file!"}
                {isDragReject && "File type not accepted, sorry!"}
                {isFileTooLarge && (
                    <div className="text-danger mt-2">
                        File is too large.
                    </div>
                )}
            </div>
        </div>
    );

}

function ImportPatientExams(props) {

    const [state, setState] = useState({
        file: {},
        showPane: props.showPane,
        loading: false,
        userAssociatedFacilities: [],
        loaded: false,
        formFields: {
            facilityID: ''
        },
        failedImports: [],
        importResponse: {},
        importFinished: false
    });

    useEffect(() => {
        var alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => alive)).subscribe(([af]) => {
            if (af?.length > 0) {

                let defaultFacility = af.find(f => f.defaultFacility === 1)?.facilityModel?.facilityID || af[0].facilityModel?.facilityID;
                let facilityID = state.formFields.facilityID === '' ? defaultFacility : state.formFields.facilityID;

                setState({
                    ...state,
                    userAssociatedFacilities: af,
                    loaded: true,
                    formFields: {
                        facilityID
                    }
                });
            }
        });

        return () => alive = false;
    }, []);

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => props.hidePane(), 500);
    }

    function handleSubmit() {
        if (state.file.name) {
            setState({
                ...state,
                loading: true
            });

            importPatientExamsExcel(state.file).then(data => {
                setState({
                    ...state,
                    file: {},
                    loading: false,
                    importResponse: data,
                    failedImports: data?.failedImports ? data.failedImports : [],
                    importFinished: true
                });

                if (data.failedImports?.filter(fi => fi.reason === 'Incorrect Format').length > 0) {
                    toast.error("Import failed");
                } else {
                    toast.success("Patient exam import has finished");
                }

            });
        } else {
            toast.error("Please select a file first");
        }
    }

    function handleErrorExport() {
        const key = "loading";
        message.loading({ content: 'Exporting Errors...', key, duration: 0 });
        let fileName = "error-results_" + Moment().format('YYYYMMDDHm') + '.xlsx';

        exportErrorList(state.failedImports).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log("Error");
        });
    }

    const antIcon = <LoadingOutlined spin />;
    const { Paragraph, Text } = Typography;

    const facilityNotFound = state.failedImports?.filter(item => item.reason === 'Facility Not Found');
    const patientNotFound = state.failedImports?.filter(item => item.reason === 'Patient Not Found');
    const examTypeNotFound = state.failedImports?.filter(item => item.reason === 'Exam Type Not Found');
    const techNotFound = state.failedImports?.filter(item => item.reason === 'Tech Not Found');
    const insuranceNotFound = state.failedImports?.filter(item => item.reason === 'Insurance Not Found');
    const providerNotFound = state.failedImports?.filter(item => item.reason === 'Provider Not Found');
    const duplicateExamFound = state.failedImports?.filter(item => item.reason === 'Duplicate Exam Found');

    const incorrectFormat = state.failedImports?.filter(item => item.reason === 'Incorrect Format');
    const errorImports = state.failedImports?.filter(item => item.reason === 'Error');

    const theme = window.sessionStorage.getItem("theme");

    const { Option } = Select;

    return (
        <Fragment>
        {/*<SlidingPane*/}
        {/*    overlayClassName='pinkCard'*/}
        {/*    className={theme === 'dark' ? "dark-theme" : 'light-theme'}*/}
        {/*    isOpen={state.showPane || false}*/}
        {/*    title='Import Patient Exams'*/}
        {/*    onRequestClose={hidePane}*/}
        {/*    shouldCloseOnEsc={false}*/}
        {/*>*/}

            <div className={state.failedImports?.length > 0 ? "slide-pane-body" : "slide-pane-body h-100"}>
                <MyDropzone setFile={(file) => setState({ ...state, file })} />
                {state.file?.name && <pre className="mb-4">{state.file.name}</pre>}
                <button className="btn btn-submit" onClick={handleSubmit}>{state.loading ? <Spin indicator={antIcon} className="text-white" /> : <span>Import Patient Exams</span>} </button>

                {state.importFinished &&
                    <Result
                        status="success"
                        title="Import Completed"
                        subTitle="Please check below for any errors."
                    >
                        <div className="desc">
                            <Paragraph className="text-center">
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16
                                    }}
                                >
                                    <CheckCircleTwoTone twoToneColor="#52c41a" /> &nbsp; {state.importResponse?.totalSuccess} of {state.importResponse.totalCount} have successfully been imported.
                                <br />
                                    <CloseCircleTwoTone twoToneColor="#ff4d4f" /> &nbsp; {state.importResponse?.totalFailed} of {state.importResponse.totalCount} have failed. Please see below for more details.
                        </Text>
                            </Paragraph>
                            <div className="row">
                                {incorrectFormat.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The upload sheet has incorrectly formatted data. Please contact your system admin.
                                    </Text>
                                        </Paragraph>
                                    </div>
                                }

                                {duplicateExamFound.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the associated PatientExamID already exists:
                                    </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {duplicateExamFound.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.item.patientExamID}</b> -
                                                Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {facilityNotFound.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the associated FacilityID is not found:
                                    </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {facilityNotFound.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.item.facilityID}</b> -
                                                Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {patientNotFound.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the associated PatientID is not found:
                                    </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {patientNotFound.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.item.patientID}</b> -
                                                Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {examTypeNotFound.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the associated ExamTypeID is not found:
                                    </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {examTypeNotFound.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.item.examTypeID}</b> -
                                                Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {techNotFound.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the associated PerformingTechUserID is not found:
                                    </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {techNotFound.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.item.performingTechUserID}</b> -
                                                Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {insuranceNotFound.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the associated InsuranceID is not found:
                                    </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {insuranceNotFound.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.item.insuranceID}</b> -
                                                Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {providerNotFound.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed because the associated ProviderID is not found:
                                    </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {providerNotFound.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <InfoCircleTwoTone twoToneColor="#ff4d4f" />&nbsp;
                                                    <b>{item.item.providerID}</b> -
                                                Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }

                                {errorImports.length > 0 &&
                                    <div className="col col-lg-6">
                                        <Paragraph>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                The following have failed due to an unknown cause. Please check that the column headers have no leading or trailing spaces. These may need to be input manually:
                                    </Text>
                                        </Paragraph>

                                        <div className="display-third">
                                            {errorImports.map((item, idx) => (

                                                <Paragraph key={idx}>
                                                    <CloseCircleTwoTone twoToneColor="#ff4d4f" /> &nbsp;
                                                    <b>Patient Exam ID: {item.item.patientExamID}</b> -
                                                Row Number: <b>{item.rowNumber}</b>
                                                </Paragraph>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Result>
                }
            </div>
            {/*state.failedImports?.length > 0 &&
                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-submit" onClick={handleErrorExport}>Export Errors</button>
                    </div>
                </div>
            */}
            {/*</SlidingPane>*/}
        </Fragment>
    );
}

export default withRouter(ImportPatientExams);