//import axios from 'axios';
//import Globals from "./globals";

//// https://dev.to/teroauralinna/global-http-request-and-response-handling-with-the-axios-interceptor-30ae
//// https://codepen.io/teroauralinna/pen/vPvKWe?editors=0010

//let handlerEnabled = false;

//const requestHandler = (request) => {
//    if (handlerEnabled) {
//        console.log('Request: ' + request.url + ' ' + JSON.stringify(request.data));
//    }

//    // add the bearer token to the request header
//    request.headers.Authorization = 'Bearer ' + Globals.userInfo.bearer

//    return request;
//};

//const errorHandler = (error) => {
//    if (handlerEnabled) {
//        console.log('Error: ' + error.response.status + ' ' + error.config.url);
//    }
//    return Promise.reject({ ...error });
//};

//const successHandler = (response) => {
//    if (handlerEnabled) {
//        console.log('Success: ' + response.config.url);
//    }
//    return response;
//};

//const axiosInstance = axios.create({
//    baseURL: window.api,
//    headers: {
//        'Access-Control-Allow-Origin': 'http://localhost:3000',
//        'Authorization': ''
//    }
//});

//axiosInstance.interceptors.request.use(
//    request => requestHandler(request)
//);

//axiosInstance.interceptors.response.use(
//    response => successHandler(response),
//    error => errorHandler(error)
//);


//export default axiosInstance;

import axios from 'axios';
import Globals from './globals';
import { refreshToken } from '../common/services/TokenService';

// https://dev.to/teroauralinna/global-http-request-and-response-handling-with-the-axios-interceptor-30ae
// https://codepen.io/teroauralinna/pen/vPvKWe?editors=0010

let handlerEnabled = false;

// Create a list to hold the request queue
const refreshAndRetryQueue = [];

//how many times to retry
var retryTimes = 3;

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const requestHandler = (request) => {
    if (handlerEnabled) {
        console.log('Request: ' + request.url + ' ' + JSON.stringify(request.data));
    }

    // add the bearer token to the request header
    request.headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    if (Globals.bearer) {
        request.headers.Authorization = 'Bearer ' + Globals.bearer;
    }
    

    return request;
};

// const errorHandler = (error) => {
//     if (handlerEnabled) {
//         console.log('Error: ' + error.response.status + ' ' + error.config.url);
//     }
//     return Promise.reject({ ...error });
// };

const successHandler = (response) => {
    if (handlerEnabled) {
        console.log('Success: ' + response.config.url);
    }
    return response;
};


const axiosInstance = axios.create({
    baseURL: window.api,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

axiosInstance.interceptors.request.use(
    request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status.toString().startsWith("4")) {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    // Refresh the access token
                    const newAccessToken = await refreshToken(Globals.tokenStuff);

                    Globals.tokenStuff = newAccessToken;
                    Globals.bearer = newAccessToken?.accessToken;

                    if (newAccessToken) {
                        // Update the request headers with the new access token
                        error.config.headers['Authorization'] = `Bearer ${newAccessToken?.accessToken}`;
                    }

                    // Retry all requests in the queue with the new token
                    refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                        axiosInstance
                            .request(config)
                            .then((response) => resolve(response))
                            .catch((err) => reject(err));
                    });

                    // Clear the queue
                    refreshAndRetryQueue.length = 0;

                    retryTimes--;

                    // Retry the original request
                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    // Handle token refresh error
                    // You can clear all storage and redirect the user to the login page
                    throw refreshError;
                } finally {
                    isRefreshing = false;
                }
            }

            // Add the original request to the queue
            return new Promise((resolve, reject) => {
                refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
            });
        }
        // Return a Promise rejection if the status code is not 401
        return Promise.reject(error);
    }
);


export default axiosInstance;
