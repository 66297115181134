import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { getDeletedPatients, loadingPatientResults, patientResults, restorePatient } from '../../common/services/ApiService';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { ColumnToggle, Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';

import Moment from 'moment';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { message } from 'antd';

class ViewDeletedPatients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deletedPatients: [],
            loaded: false,
            showPane: props.showPane
        }
    }

    componentDidMount() {
        getDeletedPatients().then(data => {
            this.setState({
                deletedPatients: data,
                loaded: true
            });
        });
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    restorePatient = (patientID) => {
        let postdata = {
            patientID
        }

        restorePatient(postdata).then(data => {
            if (data > 0) {
                message.success("This patient has been restored");

                this.hidePane();
            } else {
                message.error("Something went wrong");
            }
        });
    }

    render() {
        const { SearchBar, ClearSearchButton } = Search;
        const restorePatient = (patientID) => this.restorePatient(patientID);

        function restoreFormatter(cell, row) {
            return (
                <button className="btn alert-info" onClick={() => restorePatient(row.patientModel.patientID)}>Restore this patient</button>
            );
        }

        const { ToggleList } = ColumnToggle;
        const columns = [{
            dataField: 'patientModel.mrn',
            text: 'MRN #'
        }, {
            dataField: 'patientModel.lName',
            text: 'Last Name'
        }, {
            dataField: 'patientModel.fName',
            text: 'First Name'
        }, {
            dataField: 'patientModel.dob',
            text: 'DOB',
            formatter: cell => Moment(new Date(cell)).format('L'),
            filterValue: cell => Moment(new Date(cell)).format('L')
        }, {
            dataField: 'facilityModel.facilityName',
            text: 'Facility'
        }, {
            dataField: 'patientModel.address1',
            text: 'Address'
        }, {
            dataField: 'dumdum',
            text: 'Restore this patient',
            isDummyField: true,
            formatter: restoreFormatter
        }];

        const sizePerPageOptionRenderer = ({
            text,
            page,
            onSizePerPageChange
        }) => (
            <li
                key={text}
                role="presentation"
                className="form-control-custom hover"
                tabIndex="-1"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'pink' }}
            >
                <span className="a hover"
                    tabIndex="-1"
                    role="menuitem"
                    data-page={page}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        onSizePerPageChange(page);
                    }}
                    style={{ color: 'rgba(243, 0, 121, 1)' }}
                >
                    {text}
                </span>
            </li>
        );

        const pagination = {
            sizePerPage: 5,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            sizePerPageOptionRenderer
        };

        const theme = window.sessionStorage.getItem("theme");

        return (
            <SlidingPane
                overlayClassName='pinkCard'
                className={theme === 'dark' ? "dark-theme" : 'light-theme'}
                isOpen={this.state.showPane || false}
                /*title={<div style={{ height: '50px' }}><span style={{marginTop: '15px', position: 'fixed' }}>Add a Candidate</span> <button className="btn btn-submit float-right">Submit</button></div>}*/
                title='Deleted Patients'
                onRequestClose={this.hidePane}
                shouldCloseOnEsc={false}
            >

                <div className="slide-pane-body h-100">
                    {this.state.loaded &&
                        <ToolkitProvider
                            keyField='patientModel.patientID'
                            data={this.state.deletedPatients}
                        columns={columns}
                        search
                            columnToggle
                        >
                            {
                                props => (
                                    <div>
                                        <SearchBar {...props.searchProps} className="margin-top-20" placeholder="Search for keyword(s)" />
                                        <ClearSearchButton {...props.searchProps} />
                                        <hr />
                                        <BootstrapTable
                                            pagination={paginationFactory(pagination)}
                                            {...props.baseProps}
                                            filter={filterFactory()}
                                            hover condensed />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    }
                </div>
            </SlidingPane>
        );
    }
}

export default withRouter(ViewDeletedPatients);