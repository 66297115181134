import Axios from '../../config/axios';

export function getAuditResults() {
    return Axios.get(`/api/GetAuditResults`
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getImportAuditResults() {
    return Axios.get(`/api/GetImportAuditResults`
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getAuditSearchInformation() {
    return Axios.get(`/api/GetAuditSearchInformation`
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getAuditSearchResults(postdata) {
    return Axios.post(`/api/GetAuditSearchResults`, postdata
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getAuditResultsByImportID(importID) {
    return Axios.get(`/api/GetAuditResultsByImportID`, {
        params: {
            importID
        }
    }
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}