import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { toast, confirm } from '@rickylandino/react-messages';
import NumberFormat from 'react-number-format';

import Card from 'react-bootstrap/Card';
import '../../../css/card.css';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { allFacilities, allIssuers } from '../../../common/services/ReportService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useToggle } from '../../Helpers/CustomHooks';
import ManageFacility from '../ManageFacility';


function ManageFacilities(props) {

    const [state, setState] = useState({
        facilities: [],
        issuers: [],
        loaded: false
    });

    const [showManageFacilityPane, setShowManageFacilityPane] = useToggle(false);
    const [selectedFacilityID, setSelectedFacilityID] = useState(null);

    var alive = true;

    useEffect(() => {

        combineLatest(
            allFacilities,
            allIssuers
        ).pipe(takeWhile(() => alive)).subscribe(([facilities, issuers]) => {
            setState({
                facilities,
                issuers,
                loaded: true
            });
        });

        return () => { alive = false };
    }, []);

    function openNewFacility() {
        setSelectedFacilityID(null);
        setShowManageFacilityPane();
    }

    const issuerFormatter = (cell, row, idx, formatExtraData) => {
        
        let val = formatExtraData['issuers'].find(i => i.issuerofPatientID === cell)?.issuerName
        
        return (
            <div>
                {val}
            </div>
        );
    }

    const columns = [{
        dataField: 'facilityName',
        text: 'Name'
    }, {
            dataField: 'issuerofPatientID',
            text: 'Issuer',
            formatter: issuerFormatter,
            formatExtraData: { issuers: state.issuers }
        }, {
            dataField: 'facilityNumber',
            text: 'Facility Number'
        }, {
            dataField: 'facilityNickname',
            text: 'Facility Nickname'
        }];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true
    };

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            setSelectedFacilityID(row.facilityID);
            setShowManageFacilityPane();
        }
    };

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role="presentation"
            className="form-control-custom hover"
            tabIndex="-1"
            data-page={page}
            onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            }}
            style={{ color: 'pink' }}
        >
            <span className="a hover"
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'rgba(243, 0, 121, 1)' }}
            >
                {text}
            </span>
        </li>
    );

    const pagination = {
        sizePerPage: 10,
        sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
        sizePerPageOptionRenderer
    };

    const rowClasses = (row, rowIndex) => {

        if (row.inactiveYN) {
            return "user-inactive";
        }

    };

    const username = JSON.parse(window.sessionStorage.getItem("userInfo"))?.username;

    return (
        <div>
            {state.loaded &&
                 <Card className="pinkCard">
                <Card.Header><span>Manage Facilities</span> <i className="far fa-plus-square fa-15x hover float-right" onClick={openNewFacility} />
                        </Card.Header>
                        <Card.Body>
                            <div className="fullTable">
                                <ToolkitProvider
                                    keyField='facilityID'
                                    data={state.facilities}
                                    columns={columns}
                                    columnToggle
                                >
                                    {
                                        props => (
                                            <div>
                                                <BootstrapTable
                                                    pagination={paginationFactory(pagination)}
                                                    {...props.baseProps}
                                                    rowEvents={rowEvents}
                                                    selectRow={selectRow}
                                                    rowClasses={rowClasses}
                                                    hover condensed />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </Card.Body>
                        
                    </Card>
            }

            {showManageFacilityPane && <ManageFacility hidePane={() => setShowManageFacilityPane()} facilityID={selectedFacilityID} />}
        </div>
    );
}

export default withRouter(ManageFacilities);