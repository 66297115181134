import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Modal } from 'react-bootstrap';

import { toast } from '@rickylandino/react-messages';

import { updateProvider } from '../../../common/services/MaintenanceService';

import Globals from '../../../config/globals';

import { Select } from 'antd';

export default function ProviderModal(props) {
    const { register, handleSubmit, setValue, getValues,
        formState: { errors }, control } = useForm();

    const [state, setState] = useState({
        facilityList: Globals.associatedFacilities
    });

    const { Option } = Select;

    const [modalInfo, setModalInfo] = useState({
        showModal: false,
        providerItem: {
            providerModel: {
                fName: '',
                mName: '',
                lName: '',
                title: '',
                inactiveYN: false,
                npi: ''
            }
        },
        selectedFacilities: []
    });

    const [duplicateModalInfo, setDuplicateModalInfo] = useState({
        showModal: false,
        providerItem: {
            providerModel: {
                fName: '',
                mName: '',
                lName: '',
                title: '',
                inactiveYN: false,
                npi: ''
            }
        },
        selectedFacilities: [],
        filteredFacilityList: [],
        filteredSelectedFacilities: [],
        existingFacilities: []
    });

    useEffect(() => {
        setModalInfo({
            ...modalInfo,
            showModal: true
        });
    }, []);

    function closeModal() {
        setModalInfo({
            showModal: false,
            providerItem: {
                providerModel: {
                    fName: '',
                    mName: '',
                    lName: '',
                    title: '',
                    inactiveYN: false,
                    npi: ''
                }
            }
        });
    }

    function closeDuplicateModal() {
        setDuplicateModalInfo({
            showModal: false,
            providerItem: {
                providerModel: {
                    fName: '',
                    mName: '',
                    lName: '',
                    title: '',
                    inactiveYN: false,
                    npi: ''
                }
            },
            selectedFacilities: [],
            filteredFacilityList: [],
            filteredSelectedFacilities: [],
            existingFacilities: []
        });
    }

    function handleUpdate(postdata) {

        var fromDuplicateModal = false;

        if (!postdata) {
            var providerItem = modalInfo.providerItem.providerModel;

            providerItem.inactiveYN = getValues().inactiveYN ? 1 : 0;
            providerItem.fName = getValues().fName;
            providerItem.mName = getValues().mName;
            providerItem.lName = getValues().lName;
            providerItem.title = getValues().title;
            providerItem.npi = getValues().npi;

            postdata = {
                provider: providerItem,
                guidList: modalInfo.selectedFacilities,
                userID: JSON.parse(window.sessionStorage.getItem("userInfo"))?.userId
            }
        } else {
            fromDuplicateModal = true;
        }

        if (postdata.guidList?.length > 0) {
            updateProvider(postdata).then(data => {
                if (data?.providerModel?.providerID) {
                    closeModal();

                    let filteredFacilityList = [];

                    state.facilityList.forEach(f => {
                        let toReturn = true;

                        data.facilityList.forEach(ef => {
                            if (ef.facilityID === f.facilityModel.facilityID) {
                                toReturn = false;
                            }
                        });

                        if (toReturn) {
                            filteredFacilityList.push(f);
                        }
                    });

                    let filteredSelectedFacilities = [];


                    filteredFacilityList.forEach(ffl => {
                        let item = modalInfo.selectedFacilities.find(sf => sf === ffl.facilityModel.facilityID);

                        if (item) {
                            filteredSelectedFacilities.push(item.toString());
                        }
                    });

                    setDuplicateModalInfo({
                        showModal: true,
                        newProviderItem: providerItem,
                        providerItem: {
                            providerModel: data.providerModel
                        },
                        existingFacilities: data.facilityList,
                        filteredFacilityList,
                        filteredSelectedFacilities
                    });
                } else {
                    toast.success("Provider Updated");

                    setState({
                        ...state,
                        providerList: [...data],
                        loaded: true
                    });

                    if (fromDuplicateModal) {
                        closeDuplicateModal();
                    } else {
                        closeModal();
                    }
                }
            });
        } else {
            toast.warning("Please fill in all required information");
        }
    }

    //function handleUpdate() {
    //    var item = modalInfo.providerItem;

    //    item.inactiveYN = getValues().inactiveYN ? 1 : 0;
    //    item.fName = getValues().fName;
    //    item.lName = getValues().lName;
    //    item.mName = getValues().mName;
    //    item.title = getValues().title;

    //    insertProvider(item).then(data => {
    //        toast.success("Provider Added");

    //        if (props.from === 'manageFacility') {
    //            item.providerID = data;
    //            setTimeout(() => {
    //                props.hideModal(item);
    //            }, 1000);
    //        }

    //        closeModal();
    //    })
    //}

    function handleModalFacilityChange(selectedFacilities) {
        setModalInfo({
            ...modalInfo,
            selectedFacilities
        });
    }

    function handleSecondModalFacilityChange(selectedFacilities) {
        setDuplicateModalInfo({
            ...duplicateModalInfo,
            filteredSelectedFacilities: selectedFacilities
        });
    }

    function interceptUpdate() {

        let postdata = null

        if (duplicateModalInfo?.providerItem?.providerModel) {

            let guidList = duplicateModalInfo.filteredSelectedFacilities;

            for (var i = 0; i < duplicateModalInfo.existingFacilities.length; i++) {
                var ef = duplicateModalInfo.existingFacilities[i];
                guidList.push(ef.facilityID);
            }

            postdata = {
                provider: duplicateModalInfo.providerItem.providerModel,
                guidList,
                userID: JSON.parse(window.sessionStorage.getItem("userInfo"))?.userId
            }
        }

        handleUpdate(postdata);
    }

    function interceptAdd() {
        let postdata = {
            overrideLogic: true,
            provider: duplicateModalInfo.newProviderItem,
            guidList: duplicateModalInfo.filteredSelectedFacilities,
            userID: JSON.parse(window.sessionStorage.getItem("userInfo"))?.userId
        }

        handleUpdate(postdata);
    }

    return (
        <>
        <Modal size='lg' show={modalInfo.showModal} backdrop='static' onHide={closeModal}>
                <Modal.Header>
                    <h1>Update Provider</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                        <div className="row">
                            <div className="form-group col-lg-5 col-12">
                                <label className="form-label">First Name</label>
                                <input className="form-control-custom" {...register("fName")}/>
                            </div>
                            <div className="form-group col-lg-2 col-12">
                                <label className="form-label">Middle Initial</label>
                                <input className="form-control-custom" {...register("mName")}/>
                            </div>
                            <div className="form-group col-lg-5 col-12">
                                <label className="form-label">Last Name</label>
                                <input className="form-control-custom" {...register("lName")}/>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="form-label">Title</label>
                                <input className="form-control-custom" {...register("title")} />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="form-label">NPI</label>
                                <input className="form-control-custom" {...register("npi")} />
                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Facilities <span className="color-pink">*</span></div>
                                <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    allowClear
                                    placeholder="Please select"
                                    onChange={handleModalFacilityChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    value={modalInfo.selectedFacilities}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {state.facilityList.map((f, idx) => <Option key={f.facilityModel.facilityID} value={f.facilityModel.facilityID}>{f.facilityModel.facilityName}</Option>)}
                                </Select>
                            </div>

                            <div className="form-group col-6">
                                <label className="form-label">Set Inactive</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" {...register("inactiveYN")} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={() => handleUpdate(null)}>Save</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={duplicateModalInfo.showModal} backdrop='static' onHide={closeDuplicateModal}>
                <Modal.Header>
                    <h1>Existing Provider Found</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                        <div className="row">
                            <div className="form-group col-12">
                                <h3>A provider with the same name was found. See details below:</h3>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Name</label>
                                <p>{duplicateModalInfo.providerItem.providerModel.fName} {duplicateModalInfo.providerItem.providerModel.mName} {duplicateModalInfo.providerItem.providerModel.lName}</p>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Title</label>
                                <p>{duplicateModalInfo.providerItem.providerModel.title}</p>
                            </div>

                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">NPI</label>
                                <p>{duplicateModalInfo.providerItem.providerModel.npi}</p>
                            </div>

                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Associated Facilities</label>
                                {duplicateModalInfo?.existingFacilities?.map((f, idx) => <p key={idx}>{f.facilityName}</p>)}
                            </div>

                            <hr />

                            <h3>Would you like to associate the selected facilities to this provider?</h3>

                            <div className="form-group col col-12">
                                <div className="form-label"></div>
                                <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    allowClear
                                    placeholder="Please select"
                                    onChange={handleSecondModalFacilityChange}
                                    className="form-control-custom w-60"
                                    bordered={false}
                                    value={duplicateModalInfo?.filteredSelectedFacilities}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {duplicateModalInfo?.filteredFacilityList.map((f, idx) => <Option key={f.facilityModel.facilityID} value={f.facilityModel.facilityID}>{f.facilityModel.facilityName}</Option>)}
                                </Select>

                                <button className="btn btn-submit float-right" onClick={interceptUpdate}>Update Existing Provider</button>
                            </div>

                            <div className="d-block w-100">
                                <div className="text-hr">OR</div>
                            </div>

                            <h3>Proceed with adding this new provider?</h3>
                            <div className="text-right w-100">
                                <button className="btn btn-submit" onClick={interceptAdd}>Add As New Provider</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={closeDuplicateModal}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}