import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReportLibraryTable from './ReportLibraryTable';
import Axios from '../../../config/axios';
import NumberFormat from 'react-number-format'
import { Button, Modal } from 'react-bootstrap';
import { MyToast } from '../../Helpers/Toast';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ReportLibrary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            providers: [],
            provider: null,
            insuranceCompany: null,
            loading: true,
            loadProvider: true,
            showModal: false,
            formFields: {
                defRadiologist: "0",
                inactiveYN: "0"
            },
            showToast: false,
            toastContent: {
                title: '',
                content: ''
            }
        }

        this.handleClick = this.handleClick.bind(this);
        this.GetAllProviders = this.GetAllProviders.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleToast = this.toggleToast.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.notifyFail = this.notifyFail.bind(this);
        this.notifySuccess = this.notifySuccess.bind(this);
        //this.getPatient = this.getPatient.bind(this);
        //this.getInsuranceCompany = this.getInsuranceCompany.bind(this);
        //this.renderTestReport = this.renderTestReport.bind(this);

    }

    componentDidMount() {
        this.GetAllProviders();
    }

    notifySuccess = () => toast.success('Provider updated.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });

    notifyFail = () => toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'backToResults':

                this.setState({
                    patient: null,
                    loading: true,
                    loadPatient: true
                }, () => this.GetAllPatients());
                break;
            case 'closeModal':
                this.setState({
                    showModal: !this.state.showModal
                });
                break;
            default:
                break;
        }
    }

    editProvider(provider) {

        this.setState({
            formFields: provider,
            showModal: true
        });
    }

    toggleModal(event) {
        event.preventDefault();

        this.setState({
            formFields: {
                defRadiologist: "0",
                inactiveYN: "0"
            },
            showModal: !this.state.showModal
        });
    }

    toggleToast(toastContent) {
        if (toastContent) {
            this.setState({
                showToast: !this.state.showToast,
                toastContent
            });
        } else {
            this.setState({
                showToast: !this.state.showToast
            });
        }
    }

    GetAllProviders() {
        Axios.get(`/api/GetAllProviders`
        ).then(response => {
            this.setState({
                providers: response.data,
                loading: false
            });
        }).catch(error => {

            console.log(error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = "";
        if (target.type === 'checkbox') {
            value = target.checked === true ? "1" : "0";
        }
        else {
            value = target.value;
        }
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit(event) {
        event.preventDefault();

        let ffCopy = { ...this.state.formFields };

        ffCopy.phoneNo = ffCopy.phoneNo.toString();
        ffCopy.phoneNo = ffCopy.phoneNo.replace('(', '');
        ffCopy.phoneNo = ffCopy.phoneNo.replace(')', '');
        ffCopy.phoneNo = ffCopy.phoneNo.replace('-', '');
        ffCopy.phoneNo = ffCopy.phoneNo.replace(' ', '');

        ffCopy.phoneNo = parseInt(ffCopy.phoneNo);

        let postdata = {
            provider: ffCopy
        };

        let toastContent = {
            title: '',
            content: ''
        };

        if (ffCopy.providerID) {
            //if updating current entry

            Axios.post(`/api/UpdateProvider`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Provider has been added."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllProviders();
            }).catch(error => {
                this.notifyFail('Fail');
                console.log(error);
            });
        } else {
            //if new entry

            Axios.post(`/api/InsertProvider`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Provider has been added."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllProviders();
            }).catch(error => {
                console.log(error);
            });
        }
    }

    //renderTestReport(event) {
    //    event.preventDefault();

    //    //let q = Axios.defaults.baseURL + 'api/renderreport';

    //    //window.open(q + "?saveDialog=false", "_blank");     // open in new tab
    //    //window.open(q + "?saveDialog=true", "_top");         // save dialog


    //    const target = event.target;
    //    const id = target.id;

    //     const q = Axios.defaults.baseURL + "api/renderreport/";

    //    if (id === 'btnNewTab') {
    //        window.open(q + "?saveDialog=false", "_blank");
    //    } else { // btnSave
    //        window.open(q + "?saveDialog=true", "_top");
    //    }

    //}


    render() {
        return (
            <div>
                {
                    this.state.loadProvider &&
                    <div className="row">
                        <div className="col-lg-12" >
                            <div className="panel-hdr">
                                <h2>
                                    Report Library
                                </h2>
                            </div>
                            {/*<div>
                                <br />
                                <button id="btnNewTab" type="button" onClick={this.renderTestReport} className="btn btn-outline-primary margin-0-10">
                                    Generate Letter - New Tab
                                </button>                                 <br />
                                <button id="btnSave" type="button" onClick={this.renderTestReport} className="btn btn-outline-primary margin-0-10">
                                    Generate Letter - Save Dialog
                                </button>
                            </div> */}
                        </div >
                    </div >
                }
            </div>
        );
    }
}

export default withRouter(ReportLibrary);